import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useNavigate } from 'react-router-dom'
import StoreIcon from '@mui/icons-material/Store'

export default function SettingsPage() {
    const navigate = useNavigate()

    const goToPermissionGroups = () => {
        navigate('/admin/settings/permission-groups')
    }

    const goToCompanySettings = () => {
        navigate('/admin/settings/company')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box sx={{ padding: '20px 0 0 20px', display: 'flex' }}>
                <Typography
                    sx={{
                        padding: '5px 0 0 10px',
                        color: '#393a3d',
                        fontWeight: 'bold',
                        paddingBottom: '15px',
                    }}
                >
                    Configuration
                </Typography>
            </Box>
            <Divider />
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                sx={{ margin: '40px' }}
            >
                <Box>
                    <Typography
                        sx={{
                            padding: '40px',
                            color: '#393a3d',
                        }}
                    >
                        Permission Settings
                    </Typography>
                </Box>
                <Button
                    onClick={goToPermissionGroups}
                    display={'flex'}
                    sx={{
                        marginTop: '40px',
                        border: '1px solid',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <LockOutlinedIcon
                            fontSize={'large'}
                            sx={{ margin: '0 0 0 15px' }}
                        />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            sx={{
                                padding: '30px 40px 10px 10px',
                                color: '#393a3d',
                                textDecoration: 'none',
                                textTransform: 'none',
                            }}
                        >
                            Permission Groups
                        </Typography>
                        <Typography
                            sx={{
                                padding: '10px 40px 30px 20px',
                                fontSize: '12px',
                                color: '#393a3d',
                                textDecoration: 'none',
                                textTransform: 'none',
                            }}
                        >
                            Manage permission groups
                        </Typography>
                    </Box>
                </Button>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                sx={{ margin: '40px' }}
            >
                <Box>
                    <Typography
                        sx={{
                            padding: '40px',
                            color: '#393a3d',
                        }}
                    >
                        Company Settings
                    </Typography>
                </Box>
                <Button
                    onClick={goToCompanySettings}
                    display={'flex'}
                    sx={{
                        marginTop: '40px',
                        border: '1px solid',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <StoreIcon
                            fontSize={'large'}
                            sx={{ margin: '0 0 0 15px' }}
                        />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            sx={{
                                padding: '30px 40px 10px 10px',
                                color: '#393a3d',
                                textDecoration: 'none',
                                textTransform: 'none',
                            }}
                        >
                            Company info
                        </Typography>
                        <Typography
                            sx={{
                                padding: '10px 40px 30px 20px',
                                fontSize: '12px',
                                color: '#393a3d',
                                textDecoration: 'none',
                                textTransform: 'none',
                            }}
                        >
                            Manage Company info
                        </Typography>
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}
