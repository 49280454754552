import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ArrowBack } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../../organisms/Table/DataTable'
import { NumberParam, useQueryParam } from 'use-query-params'
import { useGetPermissionGroupsQuery } from '../../../../features/api/apiSlice'
import LoadingSpinner from '../../../atoms/LoadingSpinner'

const headCells = [
    {
        id: 'display_name',
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'memberCount',
        disablePadding: false,
        label: 'Members',
    },
]

export default function PermissionGroupsListPage() {
    const navigate = useNavigate()
    const [page, setPage] = useQueryParam('page', NumberParam)
    const [perPage, setPerPage] = useQueryParam('perPage', NumberParam)
    const { data, isLoading } = useGetPermissionGroupsQuery({
        page,
        perPage,
    })

    const handleOnPageChange = (page) => {
        setPage(page)
    }
    const handleOnPerPageChange = (perPage) => {
        setPage(1)
        setPerPage(perPage)
    }

    if (isLoading) {
        return <LoadingSpinner />
    }
    const goBackToAdminSettings = () => {
        navigate('/admin/settings')
    }

    const navigateToPermissionGroupDetails = (id) => {
        let path = '/admin/settings/permission-groups/:id'.replace(':id', id)
        navigate(path)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box sx={{ padding: '20px 0 0 20px', display: 'flex' }}>
                <Button
                    sx={{
                        borderColor: '#393a3d',
                        minWidth: 0,
                        width: '38px',
                        height: '38px',
                        padding: '5px',
                    }}
                    variant={'outlined'}
                    onClick={goBackToAdminSettings}
                >
                    <ArrowBack sx={{ color: '#393a3d' }} />
                </Button>
                <Typography
                    sx={{
                        padding: '5px 0 0 10px',
                        color: '#393a3d',
                        fontWeight: 'bold',
                        paddingBottom: '15px',
                    }}
                >
                    Permission Groups
                </Typography>
            </Box>
            <Divider />
            <DataTable
                rows={data.data.data}
                headCells={headCells}
                header={null}
                selectable={false}
                selectAction={null}
                initialOrder={'desc'}
                initialOrderBy={'name'}
                onRowClick={navigateToPermissionGroupDetails}
                page={page || 0}
                perPage={perPage || 10}
                total={data.data.total}
                onPerPageChange={handleOnPerPageChange}
                onPageChange={handleOnPageChange}
            />
        </Box>
    )
}
