import { createSlice } from '@reduxjs/toolkit'

const platformSlice = createSlice({
    name: 'platform',
    initialState: {
        data: localStorage.getItem('platformId'),
    },
    reducers: {
        setPlatform: (state, action) => {
            state.data = action.payload
            localStorage.setItem('platformId', action.payload)
        },
    },
})

export const { setPlatform } = platformSlice.actions
export default platformSlice.reducer
