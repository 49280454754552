import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useAuth } from '../provider/authProvider'
import { ProtectedRoute } from './ProtectedRoute'
import Login from '../components/pages/auth/Login'
import UserListPage from '../components/pages/users/UserListPage'
import UserDetailsPage from '../components/pages/users/UserDetailsPage'
import SettingsPage from '../components/pages/admin/SettingsPage'
import PermissionGroupsListPage from '../components/pages/admin/permissionGroups/PermissionGroupsListPage'
import PermissionGroupDetailsPage from '../components/pages/admin/permissionGroups/PermissionGroupDetailsPage'
import PasswordReset from '../components/pages/auth/PasswordReset'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryParamProvider } from 'use-query-params'
import CompanySettings from '../components/pages/admin/CompanySettings'
import AccountsDashboardComponent from '../components/pages/accounts/AccountsDashboardComponent'
import CrdSearch from '../components/pages/tools/CdrSearch'
import CarriersDashboardComponent from '../components/pages/carriers/CarriersDashboardComponent'
import NewAccount from '../components/pages/accounts/NewAccount'
import NewCarrier from '../components/pages/carriers/NewCarrier'

const Routes = () => {
    const { token } = useAuth()

    const routesForPublic = []

    const routesForAuthenticatedOnly = [
        {
            path: '/',
            element: (
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <ProtectedRoute />
                </QueryParamProvider>
            ),
            children: [
                {
                    path: '/',
                    element: <></>,
                },
                {
                    path: '/users',
                    element: <UserListPage />,
                },
                {
                    path: '/users/:id',
                    element: <UserDetailsPage />,
                },
                {
                    path: '/admin/settings/permission-groups',
                    element: <PermissionGroupsListPage />,
                },
                {
                    path: '/admin/settings/permission-groups/:id',
                    element: <PermissionGroupDetailsPage />,
                },
                {
                    path: '/admin/settings',
                    element: <SettingsPage />,
                },
                {
                    path: '/admin/settings/company',
                    element: <CompanySettings />,
                },
                {
                    path: '/accounts',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'chart-of-accounts'}
                        />
                    ),
                },
                {
                    path: '/accounts/chart-of-accounts',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'chart-of-accounts'}
                        />
                    ),
                },
                {
                    path: '/accounts/setting-overview',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'setting-overview'}
                        />
                    ),
                },
                {
                    path: '/accounts/account-transactions',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'account-transactions'}
                        />
                    ),
                },
                {
                    path: '/accounts/hosts',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'hosts'}
                        />
                    ),
                },
                {
                    path: '/accounts/proxy-balance',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'proxy-balance'}
                        />
                    ),
                },
                {
                    path: '/accounts/statistics',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'statistics'}
                        />
                    ),
                },
                {
                    path: '/accounts/graphs',
                    element: (
                        <AccountsDashboardComponent
                            initiallySelectedTab={'graphs'}
                        />
                    ),
                },
                {
                    path: '/accounts/new',
                    element: <NewAccount />,
                },
                {
                    path: '/carriers/new',
                    element: <NewCarrier />,
                },
                {
                    path: '/carriers',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'chart-of-carriers'}
                        />
                    ),
                },
                {
                    path: '/carriers/chart-of-carriers',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'chart-of-carriers'}
                        />
                    ),
                },
                {
                    path: '/carriers/setting-overview',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'setting-overview'}
                        />
                    ),
                },
                {
                    path: '/carriers/carrier-transactions',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'carrier-transactions'}
                        />
                    ),
                },
                {
                    path: '/carriers/proxy-balance',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'proxy-balance'}
                        />
                    ),
                },
                {
                    path: '/carriers/statistics',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'statistics'}
                        />
                    ),
                },
                {
                    path: '/carriers/gateways',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'gateways'}
                        />
                    ),
                },
                {
                    path: '/carriers/graphs',
                    element: (
                        <CarriersDashboardComponent
                            initiallySelectedTab={'graphs'}
                        />
                    ),
                },
                {
                    path: '/cdr-search',
                    element: <CrdSearch />,
                },
            ],
        },
    ]

    const routesForNotAuthenticatedOnly = [
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/password/view/:encodedUser',
            element: <PasswordReset />,
        },
    ]

    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ])

    return <RouterProvider router={router} />
}

export default Routes
