import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

const createData = (
    snId,
    accountName,
    accountStatus,
    group,
    cpsLimit,
    cpsLive,
    sessLimit,
    sessLive,
    hostQty,
    criticalValue
) => ({
    snId,
    accountName,
    accountStatus,
    group,
    cpsLimit,
    cpsLive,
    sessLimit,
    sessLive,
    hostQty,
    criticalValue,
})

const rows = [
    createData(
        '000000',
        'Account 1',
        'Enabled',
        'Conversational',
        '000,000',
        '000,000',
        '000,000',
        '000,000',
        '000',
        '0.00000'
    ),
    createData(
        '000000',
        'Account 2',
        'Enabled',
        'Conversational',
        '000,000',
        '000,000',
        '000,000',
        '000,000',
        '000',
        'X'
    ),
]

const ProxyBalance = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const criticalCellStyle = { color: 'red', fontWeight: 'bold' }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>SN ID</TableCell>
                            <TableCell>ACCOUNT NAME</TableCell>
                            <TableCell>ACCOUNT STATUS</TableCell>
                            <TableCell>GROUP</TableCell>
                            <TableCell>CPS LIMIT</TableCell>
                            <TableCell>CPS - LIVE</TableCell>
                            <TableCell>SESS LIMIT</TableCell>
                            <TableCell>SESS. - LIVE</TableCell>
                            <TableCell>HOST QTY.</TableCell>
                            <TableCell>CRITICAL VALUE</TableCell>
                            <TableCell>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.snId}</TableCell>
                                    <TableCell>{row.accountName}</TableCell>
                                    <TableCell>{row.accountStatus}</TableCell>
                                    <TableCell>{row.group}</TableCell>
                                    <TableCell>{row.cpsLimit}</TableCell>
                                    <TableCell>{row.cpsLive}</TableCell>
                                    <TableCell>{row.sessLimit}</TableCell>
                                    <TableCell>{row.sessLive}</TableCell>
                                    <TableCell>{row.hostQty}</TableCell>
                                    <TableCell
                                        style={
                                            row.criticalValue === 'X'
                                                ? criticalCellStyle
                                                : null
                                        }
                                    >
                                        {row.criticalValue}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={handleClick}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                Edit
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default ProxyBalance
