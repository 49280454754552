import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import TopAppBar from '../organisms/TopAppBar'
import TopAppDrawer from '../organisms/TopAppDrawer'
import { appTheme } from '../consts'

export default function DashboardLayout({ children }) {
    const [open, setOpen] = React.useState(true)

    const toggleDrawer = () => {
        setOpen(!open)
    }

    return (
        <ThemeProvider theme={appTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <TopAppBar open={open} toggleDrawer={toggleDrawer} />
                <TopAppDrawer open={open} toggleDrawer={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        paddingTop: '64px',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </ThemeProvider>
    )
}
