import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../organisms/Table/DataTable'
import AddUserDialog from './AddUserDialog'
import { useGetUsersQuery } from '../../../features/api/apiSlice'
import { NumberParam, useQueryParam } from 'use-query-params'
import LoadingSpinner from '../../atoms/LoadingSpinner'

const headCells = [
    {
        id: 'name',
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'email',
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'platform_id',
        disablePadding: false,
        label: 'Platform ID',
    },
    {
        id: 'company_id',
        disablePadding: false,
        label: 'Company ID',
    },
    {
        id: 'enabled',
        disablePadding: false,
        label: 'Status',
        customRender: (row) => (row.enabled === 1 ? 'active' : 'inactive'),
    },
]

export default function UserListPage() {
    const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false)
    const handleOpenAddUserDialog = () => setOpenAddUserDialog(true)
    const handleCloseAddUserDialog = () => setOpenAddUserDialog(false)

    const [page, setPage] = useQueryParam('page', NumberParam)
    const [perPage, setPerPage] = useQueryParam('perPage', NumberParam)
    const { data, isLoading, error } = useGetUsersQuery({ page, perPage })

    const navigate = useNavigate()

    const navigateToUserDetails = (id) => {
        let path = '/users/:id'.replace(':id', id)
        navigate(path)
    }
    const handleOnPageChange = (page) => {
        setPage(page)
    }
    const handleOnPerPageChange = (perPage) => {
        setPage(1)
        setPerPage(perPage)
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    //TODO refactor to correct error handling in the future
    if (error) {
        return (
            <div>
                {error.status} {JSON.stringify(error.data)}
            </div>
        )
    }

    return (
        <>
            <DataTable
                rows={data.data.data}
                headCells={headCells}
                header={'Users'}
                selectable={true}
                selectAction={null}
                initialOrder={'desc'}
                initialOrderBy={'name'}
                onRowClick={navigateToUserDetails}
                page={page || 0}
                perPage={perPage || 10}
                total={data.data.total}
                onPerPageChange={handleOnPerPageChange}
                onPageChange={handleOnPageChange}
                onAdd={handleOpenAddUserDialog}
            />
            <AddUserDialog
                handleOpen={openAddUserDialog}
                handleClose={handleCloseAddUserDialog}
            />
        </>
    )
}
