import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const axiosClient = axios.create({
    baseURL: baseURL,
})
const platformId = localStorage.getItem('platformId')

axiosClient.interceptors.request.use(
    function (config) {
        if (platformId && config.url !== '/login') {
            config.params = {
                ...config.params,
                platform_id: platformId,
            }
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

export const client = axiosClient
