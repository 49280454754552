import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseURL = process.env.REACT_APP_API_URL
const platformId = localStorage.getItem('platformId')

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }

        return headers
    },
})

const baseQueryWithPlatformId = async (args, api, extraOptions) => {
    const url = typeof args === 'string' ? args : args.url

    let adjustedUrl = url

    if (adjustedUrl.indexOf('?') > -1) {
        adjustedUrl += `&platform_id=${platformId}`
    } else {
        adjustedUrl = `${url}?platform_id=${platformId}`
    }
    const adjustedArgs =
        typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

    return baseQuery(adjustedArgs, api, extraOptions)
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithPlatformId,
    entityTypes: ['Api'],
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => `/me`,
        }),
        getUsers: builder.query({
            query: ({ page = 1, perPage = 10 }) => {
                return `/users?page=${page}&per_page=${perPage}`
            },
        }),
        getUser: builder.query({
            query: (userId) => `/users/${userId}`,
        }),
        getUserPermissions: builder.query({
            query: (userId) => `/users/${userId}/permissions`,
        }),
        getPermissions: builder.query({
            query: () => {
                return `/permissions`
            },
        }),
        getPermissionGroups: builder.query({
            query: ({ page = 1, perPage = 10 }) => {
                return `/roles?page=${page}&per_page=${perPage}`
            },
        }),
        getPermissionGroupPermissions: builder.query({
            query: (roleId) => {
                return `/roles/${roleId}/permissions`
            },
        }),
        getPermissionGroupUsers: builder.query({
            query: ({ id = 1, page = 1, perPage = 10 }) => {
                return `/roles/${id}/users?page=${page}&per_page=${perPage}`
            },
        }),
        updateUser: builder.mutation({
            query: ({ id, body }) => ({
                url: `/users/${id}/update-access`,
                method: 'POST',
                body,
            }),
        }),
        searchUsers: builder.query({
            query: (query = '') => {
                return `/users/search?query=${query}`
            },
        }),
        updatePermissionGroup: builder.mutation({
            query: ({ id, body }) => ({
                url: `/roles/${id}`,
                method: 'PUT',
                body,
            }),
        }),
        removeUsersFromRole: builder.mutation({
            query: ({ id, body }) => ({
                url: `/role/${id}`,
                method: 'DELETE',
                body,
            }),
        }),
        assignUserToRole: builder.mutation({
            query: ({ body }) => ({
                url: `/user/role`,
                method: 'POST',
                body,
            }),
        }),
        getCompaniesNames: builder.query({
            query: () => '/companies-names',
        }),
        getRolesNames: builder.query({
            query: () => '/roles-names',
        }),
        getPermissionsNames: builder.query({
            query: () => '/permissions-names',
        }),
        createUser: builder.mutation({
            query: ({ body }) => ({
                url: `/user`,
                method: 'POST',
                body,
            }),
        }),
        getLRNLookup: builder.query({
            query: (phoneNumber) => `/lrnlookup/${phoneNumber}`,
        }),
        getAccounts: builder.query({
            query: () => '/accounts',
        }),
        getCarriers: builder.query({
            query: () => '/carriers',
        }),
        getPlatformsIds: builder.query({
            query: () => '/platforms-ids',
        }),
    }),
})

export const {
    useGetMeQuery,
    useSearchUsersQuery,
    useGetUsersQuery,
    useGetUserQuery,
    useGetUserPermissionsQuery,
    useGetPermissionsQuery,
    useGetPermissionGroupsQuery,
    useGetPermissionGroupPermissionsQuery,
    useGetPermissionGroupUsersQuery,
    useUpdateUserMutation,
    useUpdatePermissionGroupMutation,
    useRemoveUsersFromRoleMutation,
    useAssignUserToRoleMutation,
    useGetCompaniesNamesQuery,
    useGetRolesNamesQuery,
    useGetPermissionsNamesQuery,
    useCreateUserMutation,
    useGetLRNLookupQuery,
    useGetAccountsQuery,
    useGetCarriersQuery,
    useGetPlatformsIdsQuery,
} = apiSlice
