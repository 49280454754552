import * as React from 'react'
import { Menu } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

const AddContextMenu = ({ opened, handleClose }) => {
    return (
        <Menu
            id="basic-menu"
            open={opened}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '10px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '30px 40px 30px 30px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            CUSTOMERS
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Customer
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Account
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Receive Payment
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Additional Transaction
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Account Host
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            STIR / SHAKEN
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '30px 60px 0px 30px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            VENDORS
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Vendor
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Carrier
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Gateway
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Rate Deck
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '30px 40px 30px 30px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            TOOLS
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Create Rate Deck
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Import Rate Deck
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Blacklist Entry
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Call Restriction
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Test Call
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '30px 60px 0px 30px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            JOURNALS
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Note
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Reminder
                        </Typography>
                        <Typography
                            sx={{
                                color: '#393a3d',
                                paddingBottom: '15px',
                            }}
                        >
                            Notification
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '20px',
                    }}
                ></Box>
            </Box>
        </Menu>
    )
}

export default AddContextMenu
