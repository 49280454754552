import React, { useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { setPlatform } from '../../features/store/platform/platformSlice'
import { useGetPlatformsIdsQuery } from '../../features/api/apiSlice'
import { selectPlatformId } from '../../features/store/platform/platformSelector'

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: '#c8c8c8',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#c8c8c8',
                },
            },
        },
    },
})

const PlatformSelect = () => {
    const [platforms, setPlatforms] = React.useState()
    const [platformId, setPlatformId] = React.useState(
        useSelector(selectPlatformId) || null
    )
    const dispatch = useDispatch()
    const { data } = useGetPlatformsIdsQuery()
    const storePlatformId = useSelector((state) => state.platform.data)

    useEffect(() => {
        if (data && data.data) {
            setPlatforms(
                data.data?.map((e) => {
                    return { label: e.label, id: e.id }
                })
            )
        }

        if (data?.data && !storePlatformId) {
            dispatch(setPlatform(data.data[0].id))
            setPlatformId(data.data[0].id)
        }
    }, [dispatch, data, storePlatformId])

    const onPlatformSelect = (e, v) => {
        if (v) {
            dispatch(setPlatform(v.id))
            setPlatformId(v.id)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            {platforms?.length && (
                <Autocomplete
                    options={platforms}
                    getOptionLabel={(option) => option.label}
                    value={platforms.find(
                        (p) =>
                            p.id.toString() === platformId?.toString() ??
                            platforms[0].id
                    )}
                    onChange={onPlatformSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a platform"
                            variant="outlined"
                            sx={{
                                width: 200,
                                '& .MuiInputLabel-root': {
                                    color: '#c8c8c8',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#c8c8c8',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#c8c8c8',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#c8c8c8',
                                    },
                                },
                            }}
                        />
                    )}
                    sx={{
                        width: 200,
                        '& .MuiAutocomplete-inputRoot': {
                            color: '#c8c8c8',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#c8c8c8',
                            },
                        },
                    }}
                />
            )}
        </ThemeProvider>
    )
}

export default PlatformSelect
