import React, { useState } from 'react'
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const Statistics = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const rows = Array.from({ length: 200 }, (_, index) => ({
        date: '12/25/23',
        accountName: `000,000,000`,
        revenue: `000,000,000`,
        cost: `000,000,000`,
        profit: `0.00000`,
        margin: `0.00000`,
        rate: `0.00000`,
        ppm: `0`,
        ppc: `0.00000`,
        minutes: `000,000,000`,
        completed: `000,000,000`,
        inCalls: `0.00000`,
        inASR: `000,000,000`,
        outCalls: `0.00000`,
        outASR: `0.00000`,
        acd: `0.00000`,
        sdp: `000,000,000`,
        nr: `0.00000`,
        nrPercent: `0.000`,
    }))

    const isSelected = (name) => selected.indexOf(name) !== -1

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.accountName)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClickCheckbox = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={
                                        selected.length > 0 &&
                                        selected.length < rows.length
                                    }
                                    checked={
                                        rows.length > 0 &&
                                        selected.length === rows.length
                                    }
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell>ACTION</TableCell>
                            <TableCell>DATE</TableCell>
                            <TableCell>ACCOUNT NAME</TableCell>
                            <TableCell>REVENUE</TableCell>
                            <TableCell>COST</TableCell>
                            <TableCell>PROFIT</TableCell>
                            <TableCell>MARGIN</TableCell>
                            <TableCell>RATE</TableCell>
                            <TableCell>PP MIN</TableCell>
                            <TableCell>PPK CALLS</TableCell>
                            <TableCell>MINUTES</TableCell>
                            <TableCell>COMPLETED</TableCell>
                            <TableCell>IN CALLS</TableCell>
                            <TableCell>IN ASR</TableCell>
                            <TableCell>OUT CALLS</TableCell>
                            <TableCell>OUT ASR</TableCell>
                            <TableCell>ACD</TableCell>
                            <TableCell>SDP</TableCell>
                            <TableCell>NR</TableCell>
                            <TableCell>NR %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                const isItemSelected = isSelected(
                                    row.accountName
                                )

                                return (
                                    <TableRow
                                        key={index}
                                        selected={isItemSelected}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onChange={(event) =>
                                                    handleClickCheckbox(
                                                        event,
                                                        row.accountName
                                                    )
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>
                                                    Action 1
                                                </MenuItem>
                                                <MenuItem onClick={handleClose}>
                                                    Action 2
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.accountName}</TableCell>
                                        <TableCell>{row.revenue}</TableCell>
                                        <TableCell>{row.cost}</TableCell>
                                        <TableCell>{row.profit}</TableCell>
                                        <TableCell>{row.margin}</TableCell>
                                        <TableCell>{row.rate}</TableCell>
                                        <TableCell>{row.ppm}</TableCell>
                                        <TableCell>{row.ppc}</TableCell>
                                        <TableCell>{row.minutes}</TableCell>
                                        <TableCell>{row.completed}</TableCell>
                                        <TableCell>{row.inCalls}</TableCell>
                                        <TableCell>{row.inASR}</TableCell>
                                        <TableCell>{row.outCalls}</TableCell>
                                        <TableCell>{row.outASR}</TableCell>
                                        <TableCell>{row.acd}</TableCell>
                                        <TableCell>{row.sdp}</TableCell>
                                        <TableCell>{row.nr}</TableCell>
                                        <TableCell>{row.nrPercent}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default Statistics
