import { createSlice } from '@reduxjs/toolkit'
import { userData } from '../../../hooks/users/userExampleData'

//todo ref
const initialState = userData

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
})

export default usersSlice.reducer
