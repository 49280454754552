import { configureStore } from '@reduxjs/toolkit'

import usersReducer from './users/usersSlice'
import platformReducer from './platform/platformSlice'
import { apiSlice } from '../api/apiSlice'

export default configureStore({
    reducer: {
        users: usersReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        platform: platformReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
})
