import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ArrowBack } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useParams } from 'react-router'
import { Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import MultipleSelect from '../../../organisms/MultipleSelect'
import DataTable from '../../../organisms/Table/DataTable'
import { useEffect, useState } from 'react'
import {
    useGetPermissionGroupPermissionsQuery,
    useGetPermissionGroupUsersQuery,
    useGetPermissionsQuery,
    useSearchUsersQuery,
    useUpdatePermissionGroupMutation,
    // useUpdateUserMutation,
} from '../../../../features/api/apiSlice'
import LoadingSpinner from '../../../atoms/LoadingSpinner'
import { NumberParam, useQueryParam } from 'use-query-params'

const headCells = [
    {
        id: 'name',
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'email',
        disablePadding: false,
        label: 'Email',
    },
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function PermissionGroupDetailsPage() {
    const { id } = useParams()
    const [page, setPage] = useQueryParam('page', NumberParam)
    const [perPage, setPerPage] = useQueryParam('perPage', NumberParam)

    const {
        data: permissionsGroupPermissionsData,
        isLoading: isPermissionsGroupPermissionsDataLoading,
    } = useGetPermissionGroupPermissionsQuery(id)
    const {
        data: permissionsGroupUsersData,
        isLoading: isPermissionsGroupUsersDataLoading,
    } = useGetPermissionGroupUsersQuery({ id, page, perPage })

    const [displayName, setDisplayName] = useState(
        permissionsGroupPermissionsData?.data?.display_name
    )

    const { data: permissionsData, isLoading: isPermissionDataLoading } =
        useGetPermissionsQuery()

    const [permissionsGroupPermissions, setPermissionsGroupPermissions] =
        useState(
            permissionsGroupPermissionsData?.data.permissions.map(
                (permission) => permission.id
            )
        )

    useEffect(() => {
        setPermissionsGroupPermissions(
            permissionsGroupPermissionsData?.data.permissions.map(
                (permission) => permission.id
            )
        )
    }, [permissionsGroupPermissionsData?.data.permissions])

    useEffect(() => {
        setDisplayName(permissionsGroupPermissionsData?.data?.display_name)
    }, [permissionsGroupPermissionsData?.data?.display_name])

    const [searchValue, setSearchValue] = useState('')
    const [skip, setSkip] = React.useState(true)

    // const { data: searchUsersData, isLoading: isSearchUsersDataLoading } =
    //     useSearchUsersQuery(searchValue, { skip })
    const { data: searchUsersData } = useSearchUsersQuery(searchValue, { skip })

    const [updatePermissionGroup] = useUpdatePermissionGroupMutation()
    // const [removeUsersFromRole, { removeUsersFromRoleError }] =
    //     useUpdatePermissionGroupMutation()
    // const [assignUserToRole, { assignUserToRoleError }] =
    //     useUpdatePermissionGroupMutation()

    const handleOnPageChange = (page) => {
        setPage(page)
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        setSkip(e.target.value === '')
    }

    const handleOnPerPageChange = (perPage) => {
        setPage(1)
        setPerPage(perPage)
    }
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const goBackToPermissionGroupsList = () => {
        navigate('/admin/settings/permission-groups')
    }

    if (
        isPermissionsGroupPermissionsDataLoading ||
        isPermissionDataLoading ||
        isPermissionsGroupUsersDataLoading
    ) {
        return <LoadingSpinner />
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let body = {
            display_name: displayName,
            name: permissionsGroupPermissionsData?.data?.name,
            description: permissionsGroupPermissionsData?.data?.description,
            permissions: permissionsGroupPermissions,
        }
        updatePermissionGroup({ id, body })
    }

    return (
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box sx={{ padding: '20px 0 0 20px', display: 'flex' }}>
                        <Button
                            sx={{
                                borderColor: '#393a3d',
                                minWidth: 0,
                                width: '38px',
                                height: '38px',
                                padding: '5px',
                            }}
                            variant={'outlined'}
                            onClick={goBackToPermissionGroupsList}
                        >
                            <ArrowBack sx={{ color: '#393a3d' }} />
                        </Button>
                        <Typography
                            sx={{
                                padding: '5px 0 0 10px',
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            {displayName}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box>
                        <Typography
                            sx={{
                                padding: '18px 0 0 20px',
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            Details
                        </Typography>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box
                                padding={'0 0 0 7px'}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'start'}
                            >
                                <TextField
                                    sx={{ padding: '14px 0 12px 14px' }}
                                    required
                                    name="name"
                                    value={displayName}
                                    onChange={(newValue) => {
                                        setDisplayName(newValue.target.value)
                                    }}
                                    label={'Name'}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    padding: '25px 0 0 25px',
                                    color: '#393a3d',
                                    fontWeight: 'bold',
                                }}
                            >
                                Permissions
                            </Typography>
                            <Box
                                sx={{ padding: '15px 5px 0 15px' }}
                                display={'flex'}
                            ></Box>
                            <Typography
                                sx={{
                                    padding: '25px 0 20px 25px',
                                    color: '#393a3d',
                                }}
                            >
                                Select permissions available for this group
                                members
                            </Typography>
                            <MultipleSelect
                                label={'Permissions'}
                                options={permissionsData.data.map(
                                    (permissionGroup) => {
                                        return {
                                            display_name:
                                                permissionGroup.display_name,
                                            id: permissionGroup.id,
                                        }
                                    }
                                )}
                                selectedOptions={permissionsGroupPermissions}
                                handleChange={(newValue) => {
                                    setPermissionsGroupPermissions(
                                        newValue.target.value
                                    )
                                }}
                            />
                        </Box>
                        <Box>
                            <Box
                                sx={{ padding: '25px' }}
                                display={'flex'}
                                justifyContent={'start'}
                            >
                                <Button
                                    onClick={goBackToPermissionGroupsList}
                                    type="submit"
                                    variant="outlined"
                                    sx={{ width: '64px', marginRight: '20px' }}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ width: '64px' }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                        <DataTable
                            rows={permissionsGroupUsersData.data.data}
                            headCells={headCells}
                            header={'Group Members'}
                            selectable={true}
                            selectAction={
                                <Button
                                    size={'small'}
                                    sx={{
                                        border: '1px solid',
                                        borderRadius: '6px',
                                        margin: '10px',
                                    }}
                                    onClick={handleOpen}
                                >
                                    Assign members
                                </Button>
                            }
                            initialOrder={'desc'}
                            initialOrderBy={'name'}
                            onRowClick={() => {}}
                            page={page || 0}
                            perPage={perPage || 10}
                            total={permissionsGroupUsersData.data.total}
                            onPerPageChange={handleOnPerPageChange}
                            onPageChange={handleOnPageChange}
                        />
                    </Box>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        sx={{ color: '#000000', paddingBottom: '25px' }}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Assign Group Members
                    </Typography>
                    <TextField
                        onChange={handleSearch}
                        value={searchValue}
                        placeholder="Search by name"
                        id="outlined-required"
                        label="Search Users"
                        sx={{ width: '100%', paddingBottom: '25px' }}
                    />
                    <DataTable
                        rows={
                            searchUsersData
                                ? searchUsersData.data.slice(0, 10)
                                : []
                        }
                        headCells={headCells}
                        header={null}
                        selectable={true}
                        selectAction={null}
                        initialOrder={'desc'}
                        initialOrderBy={'name'}
                        onRowClick={() => {}}
                    />
                    <Box
                        sx={{ padding: '25px 0 25px 25px' }}
                        display={'flex'}
                        justifyContent={'end'}
                    >
                        <Button
                            onClick={handleClose}
                            type="submit"
                            variant="outlined"
                            sx={{ width: '72', marginRight: '20px' }}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ width: '72px' }}
                        >
                            Assign
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}
