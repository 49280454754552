export const userData = [
    {
        id: 1,
        name: 'Tom Bricks',
        platform_id: 0,
        company_id: 0,
        status: 'active',
        email: 'tbricks@example.com',
    },
    {
        id: 2,
        name: 'Alice Smith',
        platform_id: 4,
        company_id: 3,
        status: 'inactive',
        email: 'ajohnson@example.com',
    },
    {
        id: 3,
        name: 'Bob Smith',
        platform_id: 1,
        company_id: 2,
        status: 'active',
        email: 'bsmith@example.com',
    },
    {
        id: 4,
        name: 'Emily Davis',
        platform_id: 2,
        company_id: 1,
        status: 'inactive',
        email: 'edavis@example.com',
    },
    {
        id: 5,
        name: 'Jane Doe',
        platform_id: 3,
        company_id: 4,
        status: 'active',
        email: 'jdoe@example.com',
    },
    {
        id: 6,
        name: 'Ian Thomas',
        platform_id: 0,
        company_id: 0,
        status: 'inactive',
        email: 'tbricks@example.com',
    },
    {
        id: 7,
        name: 'Ronald Ferris',
        platform_id: 4,
        company_id: 3,
        status: 'active',
        email: 'ajohnson@example.com',
    },
    {
        id: 8,
        name: 'Jane Bob',
        platform_id: 1,
        company_id: 2,
        status: 'inactive',
        email: 'bsmith@example.com',
    },
    {
        id: 9,
        name: 'Bubba Davis',
        platform_id: 2,
        company_id: 1,
        status: 'active',
        email: 'edavis@example.com',
    },
    {
        id: 10,
        name: 'John Richards',
        platform_id: 3,
        company_id: 4,
        status: 'inactive',
        email: 'jrichards@example.com',
    },
    {
        id: 11,
        name: 'William Bricks',
        platform_id: 0,
        company_id: 0,
        status: 'active',
        email: 'wbricks@example.com',
    },
    {
        id: 12,
        name: 'Tommy Basden',
        platform_id: 4,
        company_id: 3,
        status: 'inactive',
        email: 'tbasden@example.com',
    },
    {
        id: 13,
        name: 'Richard Johnson',
        platform_id: 1,
        company_id: 2,
        status: 'active',
        email: 'rjohnson@example.com',
    },
    {
        id: 14,
        name: 'Emily King',
        platform_id: 2,
        company_id: 1,
        status: 'inactive',
        email: 'eking@example.com',
    },
    {
        id: 15,
        name: 'John Johnson',
        platform_id: 3,
        company_id: 4,
        status: 'active',
        email: 'jjohnson@example.com',
    },
    {
        id: 16,
        name: 'Mike Jarvis',
        platform_id: 0,
        company_id: 0,
        status: 'inactive',
        email: 'mjarvis@example.com',
    },
    {
        id: 17,
        name: 'Brain East',
        platform_id: 4,
        company_id: 3,
        status: 'active',
        email: 'beast@example.com',
    },
    {
        id: 18,
        name: 'Billy Blank',
        platform_id: 1,
        company_id: 2,
        status: 'inactive',
        email: 'bblank@example.com',
    },
    {
        id: 19,
        name: 'Kevin BonJovi',
        platform_id: 2,
        company_id: 1,
        status: 'active',
        email: 'kbonjovi@example.com',
    },
    {
        id: 20,
        name: 'David Hasslehoff',
        platform_id: 3,
        company_id: 4,
        status: 'inactive',
        email: 'dhasslehoff@example.com',
    },
]
