import * as React from 'react'
import { HelpOutline, Search, Settings } from '@mui/icons-material'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Menu, MenuItem } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import { yellow } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../provider/authProvider'
import TopAppBarMenuItem from '../atoms/TopAppBarMenuItem'
import { useGetMeQuery } from '../../features/api/apiSlice'
import LRNLookupDialog from '../organisms/Dialogs/LRNLookupDialog'
import PlatformSelect from './PlatformSelect'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const TopAppBar = ({ open, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const settingsMenuOpened = Boolean(anchorEl)
    const { setToken } = useAuth()
    const navigate = useNavigate()
    const { isLoading, error } = useGetMeQuery()
    const [LRNLookupDialogOpen, setLRNLookupDialogOpen] = React.useState(false)

    const toolsColumnItems = [
        { name: 'CDR Search', link: '/cdr-search' },
        { name: 'Create Rate Deck', link: null },
        { name: 'Blacklists', link: null },
        { name: 'Call Restrictions', link: null },
        { name: 'Blocked CIDs', link: null },
        { name: 'Call Simulator', link: null },
        {
            name: 'LRN Lookup',
            link: null,
            onClick: () => handleLRNLookupDialogOpen(),
        },
        { name: 'Test Call', link: null },
    ]

    const journalsColumnItems = [
        { name: 'Trouble Tickets', link: null },
        { name: 'Email History', link: null },
        { name: 'System History', link: null },
        { name: 'Comments', link: null },
        { name: 'Reminders', link: null },
        { name: 'Request Logs', link: null },
        { name: 'Scam Notifications', link: null },
        { name: 'System Notifications', link: null },
    ]

    const yourCompanyColumnItems = [
        { name: 'Admin Settings', link: '/admin/settings' },
        { name: 'Default Settings', link: null },
        { name: 'New Customers', link: null },
        { name: 'Manage Users', link: '/users' },
        { name: 'Manage Agents', link: null },
    ]

    const systemListsColumnItems = [
        { name: 'Account Groups', link: null },
        { name: 'Carrier Groups', link: null },
        { name: 'Billing Products', link: null },
        { name: 'Payment Terms', link: null },
        { name: 'Ticket Categories', link: null },
        { name: 'Rate Deck Destinations', link: null },
        { name: 'INTL Code Definitions', link: null },
    ]

    const handleLRNLookupDialogOpen = () => {
        setLRNLookupDialogOpen(true)
        handleClose()
    }

    const handleLRNLookupDialogClose = () => {
        setLRNLookupDialogOpen(false)
    }

    if (isLoading) {
        return null
    }

    const handleLogout = () => {
        setToken()
        navigate('/', { replace: true })
    }

    //TODO at some point move to a global handler (when API stabilizes)
    if (error && error.status === 401) {
        handleLogout()
    }

    //TODO refactor after backend permissions system is implemented and not role system
    // const canDisplayUsers = data.data.roles.all
    //     .map((role) => role.display_name)
    //     .includes('System Admin')
    // const canDisplaySettings = data.data.roles.all
    //     .map((role) => role.display_name)
    //     .includes('System Admin')
    // // commented out for build

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px',
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                style={{
                                    padding: '10px 0 0 15px',
                                    display: 'flex',
                                }}
                            >
                                12/21/2022 23:00:00 GMT
                            </Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    borderStyle: 'dotted',
                                    borderColor: '#c8c8c8',
                                    padding: '0 0 0 15px',
                                }}
                            />
                            <Box
                                style={{
                                    padding: '10px 0 0 15px',
                                    display: 'flex',
                                }}
                            >
                                Blocked Account Cap:{' '}
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    402,652
                                </Typography>
                            </Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    borderStyle: 'dotted',
                                    borderColor: '#c8c8c8',
                                    padding: '0 0 0 15px',
                                }}
                            />
                            <Box
                                style={{
                                    padding: '10px 0 0 15px',
                                    display: 'flex',
                                }}
                            >
                                Blocked CID:{' '}
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    59,619
                                </Typography>
                            </Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    borderStyle: 'dotted',
                                    borderColor: '#c8c8c8',
                                    padding: '0 0 0 15px',
                                }}
                            />
                            <Box
                                style={{
                                    padding: '10px 0 0 15px',
                                    display: 'flex',
                                }}
                            >
                                Blocked Switch Cap:{' '}
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    1,184,678
                                </Typography>
                            </Box>
                        </Box>
                        <PlatformSelect />
                        <Box sx={{ display: 'flex' }}>
                            <IconButton color="inherit">
                                <HelpOutline />
                            </IconButton>
                            <IconButton color="inherit">
                                <NotificationsIcon />
                            </IconButton>
                            <IconButton color="inherit">
                                <Search />
                            </IconButton>
                            <IconButton color="inherit">
                                <Settings onClick={handleClick} />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={settingsMenuOpened}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding:
                                                        '30px 40px 30px 30px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#393a3d',
                                                        fontWeight: 'bold',
                                                        paddingBottom: '15px',
                                                    }}
                                                >
                                                    TOOLS
                                                </Typography>
                                                {toolsColumnItems
                                                    .sort((a, b) =>
                                                        a.name.localeCompare(
                                                            b.name
                                                        )
                                                    )
                                                    .map((toolColumnItem) => (
                                                        <TopAppBarMenuItem
                                                            key={
                                                                toolColumnItem.name
                                                            }
                                                            toolColumnItem={
                                                                toolColumnItem
                                                            }
                                                        />
                                                    ))}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding:
                                                        '30px 60px 0px 30px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#393a3d',
                                                        fontWeight: 'bold',
                                                        paddingBottom: '15px',
                                                    }}
                                                >
                                                    JOURNALS
                                                </Typography>
                                                {journalsColumnItems
                                                    .sort((a, b) =>
                                                        a.name.localeCompare(
                                                            b.name
                                                        )
                                                    )
                                                    .map((toolColumnItem) => (
                                                        <TopAppBarMenuItem
                                                            key={
                                                                toolColumnItem.name
                                                            }
                                                            toolColumnItem={
                                                                toolColumnItem
                                                            }
                                                        />
                                                    ))}
                                            </Box>
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{
                                                    borderColor: '#c8c8c8',
                                                    padding: '0 0 0 15px',
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding:
                                                        '30px 40px 30px 30px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#393a3d',
                                                        fontWeight: 'bold',
                                                        paddingBottom: '15px',
                                                    }}
                                                >
                                                    YOUR COMPANY
                                                </Typography>
                                                {yourCompanyColumnItems
                                                    .sort((a, b) =>
                                                        a.name.localeCompare(
                                                            b.name
                                                        )
                                                    )
                                                    .map((toolColumnItem) => (
                                                        <TopAppBarMenuItem
                                                            key={
                                                                toolColumnItem.name
                                                            }
                                                            toolColumnItem={
                                                                toolColumnItem
                                                            }
                                                        />
                                                    ))}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding:
                                                        '30px 60px 0px 30px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#393a3d',
                                                        fontWeight: 'bold',
                                                        paddingBottom: '15px',
                                                    }}
                                                >
                                                    SYSTEM LISTS
                                                </Typography>
                                                {systemListsColumnItems
                                                    .sort((a, b) =>
                                                        a.name.localeCompare(
                                                            b.name
                                                        )
                                                    )
                                                    .map((toolColumnItem) => (
                                                        <TopAppBarMenuItem
                                                            key={
                                                                toolColumnItem.name
                                                            }
                                                            toolColumnItem={
                                                                toolColumnItem
                                                            }
                                                        />
                                                    ))}
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                            }}
                                        >
                                            <Box>
                                                You're viewing SipNav Project
                                                Delta
                                            </Box>
                                        </Box>
                                    </Box>
                                </Menu>
                            </IconButton>
                            <IconButton color="inherit">
                                <PopupState variant="popover">
                                    {(popupState) => (
                                        <React.Fragment>
                                            <Badge color="secondary">
                                                <Avatar
                                                    sx={{
                                                        bgcolor: yellow[500],
                                                        width: 20,
                                                        height: 20,
                                                        color: '#002441',
                                                    }}
                                                    {...bindTrigger(popupState)}
                                                >
                                                    S
                                                </Avatar>
                                            </Badge>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem
                                                    onClick={popupState.close}
                                                >
                                                    Profile
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={popupState.close}
                                                >
                                                    My account
                                                </MenuItem>
                                                <MenuItem
                                                    sx={{
                                                        color: 'black',
                                                    }}
                                                    onClick={() =>
                                                        handleLogout()
                                                    }
                                                >
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <LRNLookupDialog
                open={LRNLookupDialogOpen}
                onClose={handleLRNLookupDialogClose}
            />
        </>
    )
}

export default TopAppBar
