import { LinearProgress } from '@mui/material'
import * as React from 'react'

export const LoadBarVariants = {
    Gray: '#59646c',
    Red: '#a00003',
}
const LoadBar = ({ progress, variant }) => {
    return (
        <LinearProgress
            sx={{
                padding: '10px',
                backgroundColor: '#002441',
                borderColor: '#c8c8c8',
                border: '1px solid',
                borderRadius: '5px',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: variant,
                },
            }}
            variant={'determinate'}
            value={progress}
        />
    )
}

export default LoadBar
