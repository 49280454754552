import React from 'react'
import {
    CircularProgress,
    Box,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Autocomplete,
    TextField,
    TableHead,
    Grid,
    Alert,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as dayjs from 'dayjs'
import { client } from '../../../services/api'
import CDRDataTable from '../../organisms/Table/CDRDataTable'
import SearchIcon from '@mui/icons-material/Search'
import { nanpaValidation } from '../../../utils/nanpaValidation'
import {
    useGetAccountsQuery,
    useGetCarriersQuery,
} from '../../../features/api/apiSlice'
import { useGetPlatformsIdsQuery } from '../../../features/api/apiSlice'

const accountsDefaults = [{ account_id: 0, account_name: 'All' }]
const carriersDefaults = [{ carrier_id: 0, carrier_name: 'All' }]
const platformsDefaults = [
    { id: localStorage.getItem('platformId'), label: 'Current' },
]

function PlatformsList({ cdrDataLoading, onChange }) {
    const { data, isLoading } = useGetPlatformsIdsQuery()

    return (
        <>
            {!isLoading && (
                <Autocomplete
                    size="small"
                    id="platforms"
                    sx={{ width: '100%', color: '#f00 !important' }}
                    getOptionLabel={(option) => option.label}
                    getOptionKey={(option) => option.id}
                    getOptionValue={(option) => option.id}
                    renderInput={(params) => <TextField {...params} />}
                    defaultValue={platformsDefaults[0]}
                    disabled={cdrDataLoading}
                    onChange={onChange}
                    options={[...data.data]}
                />
            )}
        </>
    )
}

function AccountsList({ cdrDataLoading, onChange }) {
    const { data, isLoading } = useGetAccountsQuery()

    return (
        <>
            {!isLoading && (
                <Autocomplete
                    size="small"
                    id="accounts"
                    sx={{ width: '100%', color: '#f00 !important' }}
                    getOptionLabel={(option) => option.account_name}
                    getOptionKey={(option) => option.account_id}
                    getOptionValue={(option) => option.account_id}
                    defaultValue={accountsDefaults[0]}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={cdrDataLoading}
                    onChange={onChange}
                    options={[...accountsDefaults, ...data.data]}
                />
            )}
        </>
    )
}

function CarriersList({ cdrDataLoading, onChange }) {
    const { data, isLoading } = useGetCarriersQuery()

    return (
        <>
            {!isLoading && (
                <Autocomplete
                    size="small"
                    id="carriers"
                    sx={{ width: '100%' }}
                    getOptionLabel={(option) => option.carrier_name}
                    getOptionKey={(option) => option.carrier_id}
                    getOptionValue={(option) => option.carrier_id}
                    defaultValue={carriersDefaults[0]}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={cdrDataLoading}
                    onChange={onChange}
                    options={[...carriersDefaults, ...data.data]}
                />
            )}
        </>
    )
}

export default function CdrSearch() {
    const [accountId, setAccountId] = React.useState(accountsDefaults[0].id)
    const [carrierId, setCarrierId] = React.useState(carriersDefaults[0].id)
    const [platformId, setPlatformId] = React.useState(
        localStorage.getItem('platformId')
    )
    const [callerId, setCallerId] = React.useState('')
    const [calledNumber, setCalledNumber] = React.useState('')
    const [lrnNumber, setLrnNumber] = React.useState('')
    const [timeStart, setTimeStart] = React.useState(dayjs().subtract(1, 'h'))
    const [timeEnd, setTimeEnd] = React.useState(dayjs())
    const [onlyCompleted, setOnlyCompleted] = React.useState(false)
    const [durationMin, setDurationMin] = React.useState('')
    const [durationMax, setDurationMax] = React.useState('')

    const [lrnNumberError, setLrnNumberError] = React.useState('')
    const [responseError, setResponseError] = React.useState('')

    const [cdrData, setCdrData] = React.useState({})
    const [cdrDataLoading, setCdrDataLoading] = React.useState(false)

    const addTime = (duration, units) => {
        const newTimeEnd = dayjs(timeStart).add(duration, units)
        setTimeEnd(newTimeEnd)
    }

    const changeDurationMin = (event) => {
        setDurationMin(event.target.value)
    }

    const changeDurationMax = (event) => {
        setDurationMax(event.target.value)
    }

    const yesterday = () => {
        const start = dayjs()
            .subtract(1, 'd')
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
        const end = dayjs()
            .subtract(1, 'd')
            .hour(23)
            .minute(59)
            .second(59)
            .millisecond(999)

        setTimeStart(start)
        setTimeEnd(end)
    }

    const today = () => {
        const start = dayjs().hour(0).minute(0).second(0).millisecond(0)
        const end = dayjs()

        setTimeStart(start)
        setTimeEnd(end)
    }

    const lastHour = () => {
        const start = dayjs().subtract(1, 'h')
        const end = dayjs()

        setTimeStart(start)
        setTimeEnd(end)
    }

    const lastTenMin = () => {
        const start = dayjs().subtract(10, 'm')
        const end = dayjs()

        setTimeStart(start)
        setTimeEnd(end)
    }

    const convertDateTimeToQueryFormat = (dtStr) => {
        const dateTimeArr = dayjs(dtStr).format().split('T')
        const date = dateTimeArr[0]
        const time = dateTimeArr[1].split('-')[0]

        return `${date}%2B${time}`
    }

    const search = async () => {
        const emptyFieldError = 'Required Field'
        setResponseError('')
        setCdrDataLoading(true)

        !nanpaValidation(lrnNumber)
            ? setLrnNumberError('Incorrect phone number')
            : setLrnNumberError(false)
        !lrnNumber
            ? setLrnNumberError(emptyFieldError)
            : setLrnNumberError(false)

        if (!lrnNumberError) {
            var query = `limit=10000&lrn_number=${lrnNumber}&start_date=${convertDateTimeToQueryFormat(
                timeStart
            )}&end_time=${convertDateTimeToQueryFormat(timeEnd)}`

            if (platformId) query += `&p_id=${platformId}`
            if (accountId) query += `&account_id=${accountId}`
            if (carrierId) query += `&carrier_id=${carrierId}`
            if (callerId) query += `&src_number=${callerId}`
            if (calledNumber) query += `&dst_number=${calledNumber}`
            if (onlyCompleted) query += `&search_completed=${onlyCompleted}`
            if (durationMin) query += `&min_duration=${durationMin}`
            if (durationMax) query += `&max_duration=${durationMax}`

            try {
                const result = (await client.get(`/cdr/search?${query}`)).data
                if (result?.success) setCdrData(result)
                else {
                    setResponseError(result.message)
                }
                setCdrDataLoading(false)
            } catch (e) {
                setCdrDataLoading(false)
                let errorData
                if (e?.response?.data.message) {
                    errorData = e.response.data?.message + ' '

                    const keys = Object.keys(e.response.data?.data)

                    if (keys && keys.length) {
                        keys.forEach((k) => {
                            errorData += e.response.data?.data[k].join(' ')
                        })
                    }
                } else {
                    errorData = 'Unhandled error occured'
                }

                setResponseError(errorData)
            }
        } else {
            setCdrDataLoading(false)
            setResponseError('Unhandled error')
            return
        }
    }

    return (
        <Box sx={{ margin: '1rem' }}>
            <h1>CDR search</h1>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="cdr-search">
                    <TableHead></TableHead>
                    <TableBody>
                        <TableRow key="platform">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Platform:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <PlatformsList
                                    cdrDataLoading={cdrDataLoading}
                                    onChange={(event, newValue, reason) => {
                                        if (reason === 'clear')
                                            setPlatformId(
                                                localStorage.getItem(
                                                    'platformId'
                                                )
                                            )
                                        else setPlatformId(newValue.id)
                                    }}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="account">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Account:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <AccountsList
                                    cdrDataLoading={cdrDataLoading}
                                    onChange={(event, newValue, reason) => {
                                        if (reason === 'clear') setAccountId(0)
                                        else setAccountId(newValue.account_id)
                                    }}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="carrier">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Carrier:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <CarriersList
                                    cdrDataLoading={cdrDataLoading}
                                    onChange={(event, newValue, reason) => {
                                        if (reason === 'clear') setCarrierId(0)
                                        else setCarrierId(newValue.account_id)
                                    }}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="callerId">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Caller ID:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={callerId}
                                    onChange={(e) =>
                                        setCallerId(e.target.value)
                                    }
                                    disabled={cdrDataLoading}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="calledNumber">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Called Number:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={calledNumber}
                                    onChange={(e) =>
                                        setCalledNumber(e.target.value)
                                    }
                                    disabled={cdrDataLoading}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="lrnNumber">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                LRN Number*:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={lrnNumber}
                                    onChange={(e) =>
                                        setLrnNumber(e.target.value)
                                    }
                                    error={!!lrnNumberError}
                                    helperText={lrnNumberError}
                                    disabled={cdrDataLoading}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="timeRangeStart">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Time Range Start*:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        sx={{ width: '100%' }}
                                        value={timeStart}
                                        onChange={(dt) => setTimeStart(dt)}
                                        disabled={cdrDataLoading}
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="timeRangeEnd">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Time Range End*:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        sx={{ width: '100%' }}
                                        value={timeEnd}
                                        onChange={(dt) => setTimeEnd(dt)}
                                        disabled={cdrDataLoading}
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ marginRight: '.5rem' }}
                                    onClick={() => addTime(10, 'm')}
                                    disabled={cdrDataLoading}
                                >
                                    10 Min After
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ marginRight: '.5rem' }}
                                    onClick={() => addTime(1, 'h')}
                                    disabled={cdrDataLoading}
                                >
                                    Hour After
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => addTime(1, 'd')}
                                    disabled={cdrDataLoading}
                                >
                                    1 Day After
                                </Button>
                            </TableCell>
                        </TableRow>

                        <TableRow key="onlySearchCompletedCalls">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Only Search Completed Calls:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                                align="center"
                            >
                                <Checkbox
                                    checked={onlyCompleted}
                                    onChange={(e) =>
                                        setOnlyCompleted(e.target.checked)
                                    }
                                    disabled={cdrDataLoading}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key="duration">
                            <TableCell
                                align="right"
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                Actual Duration:
                            </TableCell>
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            htmlFor="min"
                                            style={{ marginRight: '.5rem' }}
                                        >
                                            Min
                                        </label>
                                        <TextField
                                            id="min"
                                            size="small"
                                            sx={{
                                                width: '80px',
                                                marginRight: '1rem',
                                            }}
                                            value={durationMin}
                                            onChange={changeDurationMin}
                                            disabled={cdrDataLoading}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            htmlFor="max"
                                            style={{ marginRight: '.5rem' }}
                                        >
                                            Max
                                        </label>
                                        <TextField
                                            id="max"
                                            size="small"
                                            sx={{ width: '80px' }}
                                            value={durationMax}
                                            onChange={changeDurationMax}
                                            disabled={cdrDataLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {!!responseError && (
                <Alert severity="error">
                    Error obtaining data. {responseError}
                </Alert>
            )}
            <Box sx={{ marginTop: '1rem', textAlign: 'right' }}>
                <Button
                    variant="contained"
                    sx={{ marginRight: '.5rem' }}
                    onClick={yesterday}
                    disabled={cdrDataLoading}
                >
                    Yesterday
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '.5rem' }}
                    onClick={today}
                    disabled={cdrDataLoading}
                >
                    Today
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '.5rem' }}
                    onClick={lastHour}
                    disabled={cdrDataLoading}
                >
                    Last Hour
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginRight: '.5rem' }}
                    onClick={lastTenMin}
                    disabled={cdrDataLoading}
                >
                    Last 10 min
                </Button>
                <Button
                    variant="contained"
                    onClick={search}
                    disabled={cdrDataLoading}
                >
                    {cdrDataLoading ? (
                        <CircularProgress
                            sx={{
                                maxWidth: '20px',
                                maxHeight: '20px',
                                marginRight: '.75rem',
                            }}
                        />
                    ) : (
                        <SearchIcon sx={{ marginRight: '.5rem' }} />
                    )}
                    Search
                </Button>
            </Box>
            {cdrData?.success && <CDRDataTable data={cdrData.data.aaData} />}
        </Box>
    )
}
