import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Box,
    Chip,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert,
} from '@mui/material'
import {
    useGetCompaniesNamesQuery,
    useGetRolesNamesQuery,
    useGetPermissionsNamesQuery,
    useCreateUserMutation,
} from '../../../features/api/apiSlice'
import { useState } from 'react'

export default function AddUserDialog({ handleOpen, handleClose, onSubmit }) {
    const companies = useGetCompaniesNamesQuery()?.data?.data
    const roles = useGetRolesNamesQuery()?.data?.data
    const permissions = useGetPermissionsNamesQuery()?.data?.data

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [userRoles, setUserRoles] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [enabled, setEnabled] = useState(0)
    const [errors, setErrors] = useState([])
    const [sendingForm, setSendingForm] = useState(false)
    const [showSuccessInfo, setShowSuccessInfo] = useState(false)
    const [showErrorInfo, setShowErrorInfo] = useState(false)

    const [saveUser] = useCreateUserMutation()

    const close = () => {
        resetUserFields()
        handleClose()
    }

    const hadleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowSuccessInfo(false)
        setShowErrorInfo(false)
    }

    const resetUserFields = () => {
        setName('')
        setUsername('')
        setEmail('')
        setCompany('')
        setUserRoles([])
        setUserPermissions([])
        setEnabled(0)
        setErrors([])
    }

    const handleSubmit = () => {
        setSendingForm(true)
        const body = {
            name,
            username,
            email,
            enabled,
            company,
            roles: userRoles,
            permissions: userPermissions,
        }

        saveUser({ body })
            .then((res) => {
                if (res?.error?.data?.message) {
                    setErrors(res.error.data.message)
                    setShowErrorInfo(true)
                    setShowSuccessInfo(false)
                } else {
                    setShowErrorInfo(false)
                    setShowSuccessInfo(true)
                    resetUserFields()
                    handleClose()
                }
            })
            .catch(() => {
                setShowErrorInfo(true)
            })
            .finally(() => {
                setSendingForm(false)
            })
    }

    return (
        <>
            <Dialog open={handleOpen} onClose={handleClose}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={name}
                                onChange={(val) => setName(val.target.value)}
                                error={errors?.name}
                                helperText={errors?.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="username"
                                label="Username"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={username}
                                onChange={(val) =>
                                    setUsername(val.target.value)
                                }
                                error={errors?.username}
                                helperText={errors?.username}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="User email"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={email}
                                onChange={(val) => setEmail(val.target.value)}
                                error={errors?.email}
                                helperText={errors?.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                    style={{ marginTop: '7px' }}
                                    error={errors?.company}
                                >
                                    Company
                                </InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'company',
                                    }}
                                    value={company}
                                    onChange={(newValue) =>
                                        setCompany(newValue.target.value)
                                    }
                                    variant="standard"
                                    style={{ marginTop: '23px' }}
                                    error={errors?.company}
                                    helperText={errors?.company}
                                >
                                    <MenuItem disabled selected>
                                        <em>Select company</em>
                                    </MenuItem>
                                    {companies &&
                                        companies.map((c) => (
                                            <MenuItem
                                                sx={{ color: '#393a3d' }}
                                                key={c.id}
                                                value={c.id}
                                            >
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                    error={errors?.roles}
                                >
                                    Roles
                                </InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'Roles',
                                    }}
                                    value={userRoles}
                                    onChange={(newValue) =>
                                        setUserRoles(newValue.target.value)
                                    }
                                    variant="standard"
                                    multiple
                                    renderValue={(selected) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 0.5,
                                            }}
                                        >
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={
                                                        roles.find(
                                                            (r) =>
                                                                r.id === value
                                                        ).display_name
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    error={errors?.roles}
                                    helperText={errors?.roles}
                                >
                                    <MenuItem disabled selected>
                                        <em>Select roles</em>
                                    </MenuItem>
                                    {roles &&
                                        roles.map((role) => (
                                            <MenuItem
                                                sx={{ color: '#393a3d' }}
                                                key={role.id}
                                                value={role.id}
                                            >
                                                {role.display_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                    error={errors?.permissions}
                                >
                                    Permissions
                                </InputLabel>
                                <Select
                                    inputProps={{
                                        name: 'Permissions',
                                    }}
                                    value={userPermissions}
                                    onChange={(newValue) =>
                                        setUserPermissions(
                                            newValue.target.value
                                        )
                                    }
                                    variant="standard"
                                    multiple
                                    renderValue={(selected) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 0.5,
                                            }}
                                        >
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={
                                                        permissions.find(
                                                            (r) =>
                                                                r.id === value
                                                        ).display_name
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    error={errors?.permissions}
                                    helperText={errors?.permissions}
                                >
                                    <MenuItem disabled selected>
                                        <em>Select permissions</em>
                                    </MenuItem>
                                    {permissions &&
                                        permissions.map((permission) => (
                                            <MenuItem
                                                sx={{ color: '#393a3d' }}
                                                key={permission.id}
                                                value={permission.id}
                                            >
                                                {permission.display_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={enabled}
                                        onChange={(newValue) => {
                                            setEnabled(
                                                newValue.target.checked ? 1 : 0
                                            )
                                        }}
                                    />
                                }
                                label="Enabled"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Cancel</Button>
                    <Button disabled={sendingForm} onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={showErrorInfo}
                autoHideDuration={3000}
                onClose={hadleCloseSnackBar}
            >
                <Alert severity="error" onClose={hadleCloseSnackBar}>
                    Error creating user
                </Alert>
            </Snackbar>
            <Snackbar
                open={showSuccessInfo}
                autoHideDuration={3000}
                onClose={hadleCloseSnackBar}
            >
                <Alert severity="success" onClose={hadleCloseSnackBar}>
                    User created successfully
                </Alert>
            </Snackbar>
        </>
    )
}
