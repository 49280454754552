import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const createData = (
    hostId,
    snId,
    accountName,
    sourceIp,
    destinationIp,
    techPrefix,
    mask,
    media,
    cpsLimit,
    sessLimit,
    cpsLive,
    sessLive
) => ({
    hostId,
    snId,
    accountName,
    sourceIp,
    destinationIp,
    techPrefix,
    mask,
    media,
    cpsLimit,
    sessLimit,
    cpsLive,
    sessLive,
})

const Hosts = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const rows = [
        createData(
            '0001',
            '0001',
            '501.26.725.254',
            '000.000.000.000',
            '000.000.000.000',
            '0000#',
            '32',
            'Yes',
            '0',
            '0',
            '0',
            '0'
        ),
        createData(
            '0002',
            '0002',
            '50.16.725.255',
            '000.000.000.000',
            '000.000.000.000',
            '0000#',
            '32',
            'No',
            '0',
            '0',
            '0',
            '0'
        ),
        createData(
            '0003',
            '0003',
            '45.5.4.52',
            '000.000.000.000',
            '000.000.000.000',
            '0000#',
            '32',
            'No',
            '0',
            '0',
            '0',
            '0'
        ),
    ]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>HOST ID</TableCell>
                            <TableCell>SN ID</TableCell>
                            <TableCell>ACCOUNT NAME</TableCell>
                            <TableCell>SOURCE IP</TableCell>
                            <TableCell>DESTINATION IP</TableCell>
                            <TableCell>TECH PREFIX</TableCell>
                            <TableCell>MASK</TableCell>
                            <TableCell>MEDIA</TableCell>
                            <TableCell>CPS LIMIT</TableCell>
                            <TableCell>SESS. LIMIT</TableCell>
                            <TableCell>CPS - LIVE</TableCell>
                            <TableCell>SESS. - LIVE</TableCell>
                            <TableCell>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.hostId}</TableCell>
                                    <TableCell>{row.snId}</TableCell>
                                    <TableCell>{row.accountName}</TableCell>
                                    <TableCell>{row.sourceIp}</TableCell>
                                    <TableCell>{row.destinationIp}</TableCell>
                                    <TableCell>{row.techPrefix}</TableCell>
                                    <TableCell>{row.mask}</TableCell>
                                    <TableCell>{row.media}</TableCell>
                                    <TableCell>{row.cpsLimit}</TableCell>
                                    <TableCell>{row.sessLimit}</TableCell>
                                    <TableCell>{row.cpsLive}</TableCell>
                                    <TableCell>{row.sessLive}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                key="edit"
                                                onClick={handleClose}
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                key="duplicate"
                                                onClick={handleClose}
                                            >
                                                Duplicate
                                            </MenuItem>
                                            <MenuItem
                                                key="viewGraph"
                                                onClick={handleClose}
                                            >
                                                View Graph
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default Hosts
