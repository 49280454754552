import { createTheme } from '@mui/material/styles'

export const appTheme = createTheme({
    typography: {
        allVariants: {
            color: '#c8c8c8',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    color: '#c8c8c8',
                    backgroundColor: '#002441',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#c8c8c8',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#c8c8c8',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    color: '#000',
                },
            },
        },
    },
})

export const drawerWidth = 240
