import * as React from 'react'
import { CircularProgress } from '@mui/material'

const LoadingSpinner = () => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <CircularProgress />
        </div>
    )
}

export default LoadingSpinner
