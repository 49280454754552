import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../provider/authProvider'
import DashboardLayout from '../components/layouts/DashboardLayout'

export const ProtectedRoute = () => {
    const { token } = useAuth()

    if (!token) {
        return <Navigate to="/login" />
    }

    return (
        <DashboardLayout>
            <Outlet />
        </DashboardLayout>
    )
}
