import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ArrowBack } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import MultipleSelect from '../../organisms/MultipleSelect'
import {
    useGetPermissionGroupsQuery,
    useGetPermissionsQuery,
    useGetUserQuery,
    useUpdateUserMutation,
} from '../../../features/api/apiSlice'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import { useEffect, useState } from 'react'
import { Alert, AlertTitle } from '@mui/material'

export default function UserDetailsPage() {
    const { id } = useParams()
    const { data: userData, isLoading: isUserLoading } = useGetUserQuery(id)
    const {
        data: permissionGroupsData,
        isLoading: arePermissionGroupsLoading,
    } = useGetPermissionGroupsQuery({})
    const { data: permissionsData, isLoading: arePermissionsLoading } =
        useGetPermissionsQuery({})
    const navigate = useNavigate()

    const [username, setUsername] = useState(userData?.data.user.username)
    const [email, setEmail] = useState(userData?.data.user.email)
    const [enabled, setEnabled] = useState(userData?.data.user.enabled)
    const [userPermissions, setUserPermissions] = useState(
        userData
            ? userData?.data.permissions.map((permission) => permission.id)
            : []
    )
    const [userPermissionGroups, setUserPermissionGroups] = useState(
        userData
            ? userData?.data.roles.all.map(
                  (permissionGroup) => permissionGroup.id
              )
            : []
    )

    useEffect(() => {
        setUsername(userData?.data.user.username)
        setEmail(userData?.data.user.email)
        setEnabled(userData?.data.user.enabled)
        setUserPermissions(
            userData
                ? userData?.data.permissions.map((permission) => permission.id)
                : []
        )
        setUserPermissionGroups(
            userData
                ? userData?.data.roles.all.map(
                      (permissionGroup) => permissionGroup.id
                  )
                : []
        )
    }, [
        userData,
        userData?.data.permissions,
        userData?.data.roles.all,
        userData?.data.user.enabled,
        userData?.data.user.username,
    ])

    const [updateUser, { error }] = useUpdateUserMutation()

    const goBackToUserList = () => {
        navigate('/users')
    }

    if (
        isUserLoading ||
        arePermissionGroupsLoading ||
        arePermissionsLoading ||
        !userData?.data
    ) {
        return <LoadingSpinner />
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let body = {
            username: username,
            email: email,
            roles: userPermissionGroups,
            permissions: userPermissions,
            enabled: enabled,
            platform_id: userData?.data?.user?.platform_id,
        }
        updateUser({ id, body })
    }

    return (
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box sx={{ padding: '20px 0 0 20px', display: 'flex' }}>
                        <Button
                            sx={{
                                borderColor: '#393a3d',
                                minWidth: 0,
                                width: '38px',
                                height: '38px',
                                padding: '5px',
                            }}
                            variant={'outlined'}
                            onClick={goBackToUserList}
                        >
                            <ArrowBack sx={{ color: '#393a3d' }} />
                        </Button>
                        <Typography
                            sx={{
                                padding: '5px 0 0 10px',
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            {userData.data.user.username}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box>
                        <Typography
                            sx={{
                                padding: '18px 0 0 20px',
                                color: '#393a3d',
                                fontWeight: 'bold',
                                paddingBottom: '15px',
                            }}
                        >
                            User details
                        </Typography>
                        {error && (
                            <Alert
                                severity="error"
                                style={{ width: '300px', margin: '20px' }}
                            >
                                <AlertTitle>Error</AlertTitle>
                                {error.data.message}
                            </Alert>
                        )}
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box
                                padding={'0 0 0 7px'}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'start'}
                            >
                                <TextField
                                    sx={{ padding: '14px 0 12px 14px' }}
                                    required
                                    name="username"
                                    value={username}
                                    onChange={(newValue) => {
                                        setUsername(newValue.target.value)
                                    }}
                                    label={'Username'}
                                />
                                <TextField
                                    sx={{ padding: '12px' }}
                                    required
                                    name="email"
                                    value={email}
                                    onChange={(newValue) => {
                                        setEmail(newValue.target.value)
                                    }}
                                    label={'Email'}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                sx={{
                                    padding: '25px 0 0 25px',
                                    color: '#393a3d',
                                    fontWeight: 'bold',
                                }}
                            >
                                User Status
                            </Typography>
                            <Box
                                sx={{ padding: '15px 5px 0 15px' }}
                                display={'flex'}
                            >
                                <Checkbox
                                    checked={enabled === 1}
                                    onChange={(newValue) => {
                                        setEnabled(
                                            newValue.target.checked ? 1 : 0
                                        )
                                    }}
                                    size="medium"
                                    sx={{ width: '40px' }}
                                />
                                <Typography
                                    sx={{
                                        padding: '10px 0 0 0',
                                        color: '#393a3d',
                                    }}
                                >
                                    User is{' '}
                                    {userData.data.user.enabled
                                        ? 'active'
                                        : 'inactive'}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    padding: '25px 0 20px 25px',
                                    color: '#393a3d',
                                    fontWeight: 'bold',
                                }}
                            >
                                Permissions
                            </Typography>
                            <MultipleSelect
                                label={'Permission Groups'}
                                options={permissionGroupsData.data.data.map(
                                    (permissionGroup) => {
                                        return {
                                            display_name:
                                                permissionGroup.display_name,
                                            id: permissionGroup.id,
                                        }
                                    }
                                )}
                                handleChange={(newValue) => {
                                    setUserPermissionGroups(
                                        newValue.target.value
                                    )
                                }}
                                selectedOptions={userPermissionGroups}
                            />
                            <MultipleSelect
                                label={'Permissions'}
                                options={permissionsData.data.map(
                                    (permission) => {
                                        return {
                                            display_name:
                                                permission.display_name,
                                            id: permission.id,
                                        }
                                    }
                                )}
                                handleChange={(newValue) => {
                                    setUserPermissions(newValue.target.value)
                                }}
                                selectedOptions={userPermissions}
                            />
                        </Box>
                        <Box sx={{ padding: '25px' }} display={'flex'}>
                            <Button
                                onClick={goBackToUserList}
                                type="submit"
                                variant="outlined"
                                sx={{ width: '64px', marginRight: '20px' }}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ width: '64px' }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
