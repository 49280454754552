import React, { useEffect, useState } from 'react'
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography,
    Button,
    IconButton,
    Toolbar,
} from '@mui/material'
import { Add, Notes, MoreHoriz } from '@mui/icons-material'
import ChartOfAccounts from './ChartOfAccounts'
import SettingOverview from './SettingOverview'
import AccountTransactions from './AccountTransactions'
import Hosts from './Hosts'
import ProxyBalance from './ProxyBalance'
import Statistics from './Statistics'
import { useNavigate } from 'react-router-dom'

const AccountsDashboardComponent = ({ initiallySelectedTab }) => {
    const navigate = useNavigate()

    const getTabFromUrl = (url) => {
        switch (url) {
            case 'chart-of-accounts': {
                return 0
            }
            case 'setting-overview': {
                return 1
            }
            case 'account-transactions': {
                return 2
            }
            case 'hosts': {
                return 3
            }
            case 'proxy-balance': {
                return 4
            }
            case 'statistics': {
                return 5
            }
            case 'graphs': {
                return 6
            }
            default:
                return 0
        }
    }

    const getTopUrlFromTab = (tab) => {
        switch (tab) {
            case 0: {
                return 'accounts'
            }
            case 1: {
                return 'carriers'
            }
            default:
                return 'accounts'
        }
    }

    const getUrlFromTab = (tab) => {
        switch (tab) {
            case 0: {
                return 'chart-of-accounts'
            }
            case 1: {
                return 'setting-overview'
            }
            case 2: {
                return 'account-transactions'
            }
            case 3: {
                return 'hosts'
            }
            case 4: {
                return 'proxy-balance'
            }
            case 5: {
                return 'statistics'
            }
            case 6: {
                return 'graphs'
            }
            default:
                return 'chart-of-accounts'
        }
    }

    useEffect(() => {
        setSelectedTab(getTabFromUrl(initiallySelectedTab))
    }, [initiallySelectedTab])

    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedTopTab] = useState(0)

    const handleTabChange = (event, newValue) => {
        navigate(`/accounts/${getUrlFromTab(newValue)}`, { replace: true })
    }

    const handleTopTabChange = (event, newValue) => {
        navigate(`/${getTopUrlFromTab(newValue)}`, { replace: true })
    }

    const goToNewAccount = () => {
        navigate('/accounts/new')
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Tabs
                        value={selectedTopTab}
                        onChange={handleTopTabChange}
                        textColor="inherit"
                    >
                        <Tab label="Accounts" />
                        <Tab label="Carriers" />
                        <Tab label="Platform" />
                    </Tabs>
                </Toolbar>
            </AppBar>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 1 }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="dashboard tabs"
                >
                    <Tab label="Chart of Accounts" />
                    <Tab label="Setting Overview" />
                    <Tab label="Account Transactions" />
                    <Tab label="Hosts" />
                    <Tab label="Proxy Balance" />
                    <Tab label="Statistics" />
                    <Tab label="Graphs" />
                </Tabs>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    sx={{ marginRight: 1 }}
                    onClick={goToNewAccount}
                >
                    Add new
                </Button>
                <Button
                    variant="outlined"
                    endIcon={<MoreHoriz />}
                    sx={{ marginRight: 1 }}
                >
                    More Reports
                </Button>
                <IconButton color="primary" aria-label="notes and reminders">
                    <Notes />
                </IconButton>
            </Box>
            <Box role="tabpanel" sx={{ padding: 2 }}>
                {selectedTab === 0 && (
                    <Typography>
                        <ChartOfAccounts />
                    </Typography>
                )}
                {selectedTab === 1 && (
                    <Typography>
                        <SettingOverview />
                    </Typography>
                )}
                {selectedTab === 2 && (
                    <Typography>
                        <AccountTransactions />
                    </Typography>
                )}
                {selectedTab === 3 && (
                    <Typography>
                        <Hosts />{' '}
                    </Typography>
                )}
                {selectedTab === 4 && (
                    <Typography>
                        <ProxyBalance />{' '}
                    </Typography>
                )}
                {selectedTab === 5 && (
                    <Typography>
                        <Statistics />{' '}
                    </Typography>
                )}
                {selectedTab === 6 && <Typography>Graphs </Typography>}
            </Box>
        </Box>
    )
}

export default AccountsDashboardComponent
