import * as React from 'react'
import Typography from '@mui/material/Typography'
import { Link as NavLink } from 'react-router-dom'

const TopAppBarMenuItem = ({ toolColumnItem }) => {
    return (
        <Typography
            sx={{
                color: '#393a3d',
                paddingBottom: '15px',
            }}
        >
            {toolColumnItem.link ? (
                <NavLink
                    style={{
                        textDecoration: 'none',
                    }}
                    relative="path"
                    to={toolColumnItem.link}
                >
                    {toolColumnItem.name}
                </NavLink>
            ) : toolColumnItem.onClick ? (
                <NavLink
                    onClick={toolColumnItem.onClick}
                    style={{ textDecoration: 'none' }}
                >
                    {toolColumnItem.name}
                </NavLink>
            ) : (
                toolColumnItem.name
            )}
        </Typography>
    )
}

export default TopAppBarMenuItem
