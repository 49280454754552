import * as React from 'react'
import List from '@mui/material/List'
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    Divider,
    Drawer,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const SideMenuItem = ({ item, divided, small, key }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [submenuOpen, setSubmenuOpen] = React.useState(false)
    const [submenuCoords, setSubmenuCoords] = React.useState()

    const buttonRef = React.useRef(null)
    const handleClick = (e) => {
        if (isOpen) {
            closeDrawer()
            return
        }
        setIsOpen(!isOpen)
        const coords = buttonRef.current.getBoundingClientRect()

        setSubmenuOpen(true)
        setSubmenuCoords({
            left: coords.width,
            top: coords.top,
            width: coords.width,
        })
    }

    const closeDrawer = () => {
        setIsOpen(false)
        setSubmenuOpen(false)
        setSubmenuOpen(false)
    }

    const smallStyle = {
        backgroundColor: '#173750',
    }

    const SubmenuCollapse = () => {
        return (
            <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
                key={`collapse_${item.title}`}
            >
                {item.divided && <Divider color="#fff" />}
                {item.submenu?.map((i) => {
                    return (
                        <>
                            <SideMenuItem
                                item={i}
                                small={item.divided}
                                key={`sideMenu_${i.title}`}
                            />
                            {item.divided && <Divider color="#fff" />}
                        </>
                    )
                })}
            </Collapse>
        )
    }

    return (
        <>
            <List
                component="div"
                disablePadding={small}
                key={`list_${item.title}`}
            >
                <ListItemButton
                    onClick={handleClick}
                    color={small ? '#000' : ''}
                    style={small && smallStyle}
                    ref={buttonRef}
                    width="100%"
                    key={`button_${item.title}`}
                    href={item.nav}
                >
                    {item.icon && <ListItemIcon>{<item.icon />}</ListItemIcon>}
                    <ListItemText
                        primary={item.title}
                        key={`text_${item.title}`}
                    />
                    {item.submenu?.length &&
                        (isOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>

                {item.root && item.submenu?.length ? (
                    <Drawer
                        anchor="left"
                        open={submenuOpen}
                        className="secondary-menu-container-drawer"
                        PaperProps={{
                            style: {
                                left: submenuCoords?.left,
                                top: submenuCoords?.top,
                                height: 'auto',
                                backgroundColor: '#002441',
                                color: '#fff',
                                width: '230px',
                                transition: 'none',
                                overflowY: 'auto',
                                maxHeight:
                                    window.innerHeight -
                                    parseInt(submenuCoords?.top) +
                                    'px',
                            },
                        }}
                        BackdropProps={{ style: { opacity: 0 } }}
                        // hideBackdrop={true}
                        component="nav"
                        ModalProps={{ onBackdropClick: closeDrawer }}
                        key={`drawer_${item.title}`}
                    >
                        {item.submenu?.length && <SubmenuCollapse />}
                    </Drawer>
                ) : (
                    item.submenu?.length && <SubmenuCollapse />
                )}
            </List>
        </>
    )
}

export default SideMenuItem
