import * as React from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import LoadBarIcon from '../molecules/LoadBarIcon'
import LoadBarText from '../molecules/LoadBarText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import { Storefront } from '@mui/icons-material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import BarChartIcon from '@mui/icons-material/BarChart'
import List from '@mui/material/List'
import { LoadBarVariants } from '../atoms/LoadBar'
import Button from '@mui/material/Button'
import { drawerWidth } from '../consts'
import AddIcon from '@mui/icons-material/Add'
import AddContextMenu from '../molecules/AddContextMenu'
import SideMenuItem from './SideMenuItem'

const SideMenu = ({ open }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const addMenuOpened = Boolean(anchorEl)
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const menuData = [
        {
            title: 'Dashboard',
            icon: DashboardIcon,
        },
        {
            title: 'Customers',
            icon: PeopleIcon,
            root: true,
            submenu: [
                {
                    title: 'Customers',
                },
                {
                    title: 'Accounts',
                    divided: true,
                    submenu: [
                        {
                            title: 'Chart of Accounts',
                            nav: '/accounts/chart-of-accounts',
                        },
                        {
                            title: 'Settings Overview',
                            nav: '/accounts/setting-overview',
                        },
                        {
                            title: 'Account Transactions',
                            nav: '/accounts/account-transactions',
                        },
                        {
                            title: 'Hosts',
                            nav: '/accounts/hosts',
                        },
                        {
                            title: 'Proxy Balance',
                            nav: '/accounts/proxy-balance',
                        },
                        {
                            title: 'Statistics',
                            nav: '/accounts/statistics',
                        },
                        {
                            title: 'Graphs',
                            nav: '/accounts/graphs',
                        },
                    ],
                },
                {
                    title: 'Carriers',
                    divided: true,
                    submenu: [
                        {
                            title: 'Chart of Carriers',
                            nav: '/carriers/chart-of-accounts',
                        },
                        {
                            title: 'Settings Overview',
                            nav: '/carriers/setting-overview',
                        },
                        {
                            title: 'Account Transactions',
                            nav: '/carriers/carrier-transactions',
                        },
                        {
                            title: 'Gateways',
                            nav: '/carriers/gateways',
                        },
                        {
                            title: 'Proxy Balance',
                            nav: '/carriers/proxy-balance',
                        },
                        {
                            title: 'Statistics',
                            nav: '/carriers/statistics',
                        },
                        {
                            title: 'Graphs',
                            nav: '/carriers/graphs',
                        },
                    ],
                },
                {
                    title: 'Pricing',
                },
                {
                    title: 'Pending',
                },
            ],
        },
        {
            title: 'Vendors',
            icon: Storefront,
        },
        {
            title: 'Transactions',
            icon: ShoppingCartIcon,
        },
        {
            title: 'Reporting',
            icon: BarChartIcon,
        },
    ]

    return (
        <>
            <List component="nav">
                <React.Fragment>
                    <ListItemButton key="loadbar">
                        {!open && (
                            <LoadBarIcon
                                progress={20}
                                variant={LoadBarVariants.Gray}
                            />
                        )}
                        {open && (
                            <LoadBarText
                                key="loadbarCps"
                                progress={20}
                                variant={LoadBarVariants.Gray}
                                text={'CPS: 10,000'}
                            />
                        )}
                    </ListItemButton>
                    <ListItemButton>
                        {!open && (
                            <LoadBarIcon
                                progress={90}
                                variant={LoadBarVariants.Red}
                            />
                        )}
                        {open && (
                            <LoadBarText
                                progress={90}
                                variant={LoadBarVariants.Red}
                                text={'Sessions: 100,000'}
                            />
                        )}
                    </ListItemButton>

                    {menuData.map((item) => (
                        <SideMenuItem item={item} />
                    ))}
                </React.Fragment>
            </List>
            <AddContextMenu opened={addMenuOpened} handleClose={handleClose} />
            {open && (
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: '30px',
                        width: drawerWidth - 5,
                    }}
                >
                    <Button
                        onClick={handleClick}
                        variant="outlined"
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            borderColor: 'white',
                            borderRadius: '20px',
                            width: '50%',
                        }}
                    >
                        + New
                    </Button>
                </ListItemButton>
            )}
            {!open && (
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: '30px',
                        left: '5px',
                        padding: 0,
                    }}
                >
                    <Button
                        onClick={handleClick}
                        variant="outlined"
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            borderColor: 'white',
                            borderRadius: '28px',
                        }}
                    >
                        <AddIcon />
                    </Button>
                </ListItemButton>
            )}
        </>
    )
}

export default SideMenu
