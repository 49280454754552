import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import LoadBar from '../atoms/LoadBar'

const LoadBarIcon = ({ progress, variant }) => {
    return (
        <ListItemText
            primary={
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {progress}%
                        </Typography>
                    </Box>
                    <LoadBar progress={progress} variant={variant} />
                </Box>
            }
        />
    )
}

export default LoadBarIcon
