import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertTitle, CircularProgress, Paper } from '@mui/material'
import { client } from '../../../services/api'
import { useState } from 'react'
import { useParams } from 'react-router'

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © Sipnav '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const defaultTheme = createTheme()

export default function PasswordReset() {
    const { encodedUser } = useParams()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [temporaryPassword, setTemporaryPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        setErrorMessage('')
        setLoading(true)
        event.preventDefault()
        try {
            const resetResponse = await client.post(
                `password/reset/${encodedUser}`,
                {
                    t_password: temporaryPassword,
                    password: password,
                    c_password: passwordRepeat,
                }
            )
            if (resetResponse.data.success === true) {
                navigate('/', { replace: true })
            }
        } catch (error) {
            //TODO refactor error handling
            setLoading(false)
            if (error.response.status === 400) {
                let errorMessage = ''
                Object.keys(error.response.data.data).forEach(function (key) {
                    error.response.data.data[key].forEach((element) => {
                        errorMessage = errorMessage + element
                    })
                })
                setErrorMessage(errorMessage)
            } else if (error.response.status === 422) {
                setErrorMessage(error.response.data.data[0])
            } else {
                setErrorMessage('An error occurred. Please try again later.')
            }
        }
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/img/bg2.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light'
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            display: 'block',
                            width: '200px',
                            margin: 'auto',
                        }}
                    >
                        {' '}
                        <img
                            src={'/img/siplogo1.png'}
                            style={{ maxWidth: '100%' }}
                            alt={'logo'}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            margin: '0 32px',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Password Reset
                        </Typography>

                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            {errorMessage !== '' && (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {errorMessage}
                                </Alert>
                            )}

                            <TextField
                                onChange={(newValue) => {
                                    setTemporaryPassword(newValue.target.value)
                                }}
                                value={temporaryPassword}
                                margin="normal"
                                required
                                fullWidth
                                name="t_password"
                                label="Current password"
                                type="password"
                                id="t_password"
                                autoFocus
                            />

                            <TextField
                                onChange={(newValue) => {
                                    setPassword(newValue.target.value)
                                }}
                                value={password}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="New password"
                                type="password"
                                id="password"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                onChange={(newValue) => {
                                    setPasswordRepeat(newValue.target.value)
                                }}
                                value={passwordRepeat}
                                margin="normal"
                                required
                                fullWidth
                                name="password-repeat"
                                label="Repeat new password"
                                type="password"
                                id="password-repeat"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loading ? <CircularProgress /> : 'Submit'}
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
