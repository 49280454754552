import SearchIcon from '@mui/icons-material/Search'
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import React from 'react'
import { client } from '../../../services/api'
import { nanpaValidation } from '../../../utils/nanpaValidation'
import LoadingSpinner from '../../atoms/LoadingSpinner'

export default function LRNLookupDialog({ open, onClose }) {
    const [phoneNumber, setPhoneNumber] = React.useState()
    const [lrnData, setLRNData] = React.useState()
    const [lrnDataLoading, setLRNDataLoading] = React.useState(false)
    const [phoneNumberIsInvalid, setPhoneNumberIsInvalid] =
        React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState()

    const handleClose = () => {
        setLRNData()
        onClose()
    }

    const searchPhoneNumber = async () => {
        setErrorMessage()
        if (nanpaValidation(phoneNumber)) {
            setLRNDataLoading(true)
            try {
                const response = await client.get(`/lrnlookup/${phoneNumber}`)
                setLRNDataLoading(false)
                setLRNData(response.data.data)
            } catch (e) {
                setErrorMessage(e.message)
                setLRNDataLoading(false)
            }
        } else {
            setPhoneNumberIsInvalid(true)
            setLRNData(undefined)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
                sx={{
                    color: '#393a3d',
                }}
            >
                LRN Lookup
            </DialogTitle>
            <DialogContent>
                <TextField
                    placeholder="Phone number"
                    inputProps={{ 'aria-label': 'search phone number' }}
                    autoFocus
                    value={phoneNumber}
                    onChange={(val) => {
                        setPhoneNumber(val.target.value)
                        setPhoneNumberIsInvalid(false)
                    }}
                    type="tel"
                    pattern="+1([0-9]{3})-[0-9]{3}-[0-9]{4}"
                    required
                    error={phoneNumberIsInvalid}
                    helperText={
                        phoneNumberIsInvalid ? 'Incorrect phone number' : false
                    }
                    sx={{ width: '70%' }}
                    onKeyUp={(event) =>
                        event.key === 'Enter' && searchPhoneNumber()
                    }
                />
                <IconButton
                    type="button"
                    sx={{ p: '10px', color: '#393a3d' }}
                    aria-label="search"
                    onClick={searchPhoneNumber}
                >
                    <SearchIcon />
                </IconButton>
                <Paper sx={{ mt: 1 }}>
                    {lrnDataLoading && <LoadingSpinner />}
                    {lrnData && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell>INPUT NUMBER</TableCell>
                                    <TableCell>LRN NUMBER</TableCell>
                                    {/* <TableCell>3RD PARTY</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lrnData.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell align="left">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.inputNumber}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.lrnNumber}
                                        </TableCell>
                                        {/* <TableCell align="left">
                                            {row.thirdParty}
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
            {!!errorMessage && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
        </Dialog>
    )
}
