import React from 'react'
import { Alert } from '@mui/material'
import DataTable from './DataTable'

export default function CDRDataTable({ data }) {
    const rowsPerPage = 5
    const [page, setPage] = React.useState(0)
    const [perPage, setPerPage] = React.useState(rowsPerPage)

    if (data?.length) {
        const total = Math.ceil(data.length / rowsPerPage)
        const removeFromData = [
            'account',
            'carrier',
            'call_id_link',
            'client',
            'trouble_ticket',
        ]
        const tableData = [...data]
        tableData.forEach((el) => {
            for (const propToRemove of removeFromData) {
                delete el[propToRemove]
            }
        })

        const headCells =
            Object.getOwnPropertyNames(tableData[0])?.map((x) => {
                return {
                    id: x,
                    disablePadding: false,
                    label: x
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (c) => c.toUpperCase()),
                }
            }) ?? ''

        const handleOnPageChange = (page) => {
            setPage(page)
        }
        const handleOnPerPageChange = (perPage) => {
            setPage(1)
            setPerPage(perPage)
        }

        return (
            <DataTable
                sx={{ marginTop: '1rem', overflowY: 'auto' }}
                rows={tableData}
                headCells={headCells}
                initialOrder={'desc'}
                initialOrderBy={'start_time'}
                page={page}
                perPage={perPage}
                total={total}
                onPageChange={handleOnPageChange}
                onPerPageChange={handleOnPerPageChange}
                onRowClick={() => false}
            />
        )
    } else {
        return (
            <Alert severity="warning" sx={{ marginTop: '1rem' }}>
                No data
            </Alert>
        )
    }
}
