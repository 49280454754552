import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useEffect } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function getStyles(id, selected, theme) {
    return {
        fontWeight:
            selected.map((singleSelected) => singleSelected.id).indexOf(id) ===
            -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    }
}

export default function MultipleSelect({
    options,
    selectedOptions,
    label,
    inputRef,
    handleChange,
}) {
    const theme = useTheme()
    const [selected, setSelected] = React.useState(selectedOptions)

    useEffect(() => {
        setSelected(selectedOptions)
    }, [selectedOptions])

    return (
        <div style={{ padding: '5px 0 0 15px' }}>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="multiple-select-label">{label}</InputLabel>
                <Select
                    inputRef={inputRef}
                    multiple
                    id="multiple-select-label"
                    value={selected}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple" label={label} />}
                    renderValue={(selected) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {selected.map((value) => {
                                return (
                                    <Chip
                                        key={value}
                                        label={
                                            options.find(
                                                (option) => option.id === value
                                            ).display_name
                                        }
                                    />
                                )
                            })}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem
                            sx={{ color: '#393a3d' }}
                            key={option.id}
                            value={option.id}
                            style={getStyles(option.id, selected, theme)}
                        >
                            {option.display_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
