import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../../../provider/authProvider'
import { Alert, AlertTitle, CircularProgress, Paper } from '@mui/material'
import { client } from '../../../services/api'
import { useState } from 'react'
import Link from '@mui/material/Link'

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © Sipnav '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const defaultTheme = createTheme()

export default function Login() {
    const { setToken } = useAuth()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [resetLink, setResetLink] = useState('')
    const [resetLinkEmail, setResetLinkEmail] = useState('')

    const handleSubmit = async (event) => {
        setErrorMessage('')
        setLoading(true)
        event.preventDefault()

        try {
            const loginResponse = await client.post('/login', {
                username: username,
                password: password,
            })
            if (loginResponse.data.success === true) {
                if (loginResponse.data.data.reset_link) {
                    setPassword('')
                    setUsername('')
                    setLoading(false)
                    setResetLink(loginResponse.data.data.reset_link)
                    setResetLinkEmail(loginResponse.data.data.email)
                } else {
                    if (loginResponse.data.data.platform_ids.length) {
                        localStorage.setItem(
                            'platformId',
                            loginResponse.data.data.platform_ids[0]
                        )
                    }
                    setToken(loginResponse.data.data.token)
                    setTimeout(function () {
                        window.location.replace('/')
                    }, 500)
                }
            }
        } catch (error) {
            setLoading(false)
            if (error.response.status === 401) {
                setErrorMessage('Invalid credentials')
            } else {
                setErrorMessage('An error occurred. Please try again later.')
            }
        }
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/img/bg2.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light'
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            display: 'block',
                            width: '200px',
                            margin: 'auto',
                        }}
                    >
                        {' '}
                        <img
                            src={'/img/siplogo1.png'}
                            style={{ maxWidth: '100%' }}
                            alt={'logo'}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            margin: '0 32px',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar
                            sx={{ m: 1, bgcolor: '#fdd600', color: 'black' }}
                        >
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            User Login
                        </Typography>

                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            {errorMessage !== '' && (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {errorMessage}
                                </Alert>
                            )}

                            {resetLink !== '' && (
                                <Alert severity="info">
                                    <AlertTitle>
                                        To initiate a password reset, click the
                                        link below. An email will be sent to{' '}
                                        {resetLinkEmail}. Please note that the
                                        expiration link expires in 3h.
                                    </AlertTitle>
                                    <Link target="_href" href={resetLink}>
                                        Reset Password
                                    </Link>
                                </Alert>
                            )}

                            <TextField
                                onChange={(newValue) => {
                                    setUsername(newValue.target.value)
                                }}
                                value={username}
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                onChange={(newValue) => {
                                    setPassword(newValue.target.value)
                                }}
                                value={password}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loading ? <CircularProgress /> : 'Sign In'}
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
