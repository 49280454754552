import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const createData = (
    id,
    accountName,
    status,
    balance,
    profit,
    spentToday,
    spentYest,
    gain,
    cps,
    cpsPercent,
    sess,
    ratio,
    asr,
    acd,
    sdp,
    npf,
    gax,
    intl,
    inMediaBl,
    group
) => ({
    id,
    accountName,
    status,
    balance,
    profit,
    spentToday,
    spentYest,
    gain,
    cps,
    cpsPercent,
    sess,
    ratio,
    asr,
    acd,
    sdp,
    npf,
    gax,
    intl,
    inMediaBl,
    group,
})

const SettingOverview = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const rows = Array.from({ length: 200 }, (_, index) =>
        createData(
            `00000${index + 1}`,
            'Account One Conversational INT.',
            'Suspended Rate Deck',
            '000,000,000.00',
            '000,000,000.00',
            '000,000,000.00',
            '000,000,000.00',
            '-100',
            '0.000',
            '0.00',
            '0.00',
            '0.00',
            '0.00',
            '0.00',
            '0.00',
            '0.00',
            '0.00',
            'Yes',
            '000,000,000.00',
            'Conversational'
        )
    )

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>SN ID</TableCell>
                            <TableCell>ACCOUNT NAME</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>BALANCE</TableCell>
                            <TableCell>PROFIT</TableCell>
                            <TableCell>SPENT TODAY</TableCell>
                            <TableCell>SPENT YEST.</TableCell>
                            <TableCell>GAIN %</TableCell>
                            <TableCell>CPS</TableCell>
                            <TableCell>CPS %</TableCell>
                            <TableCell>SESS.</TableCell>
                            <TableCell>RATIO</TableCell>
                            <TableCell>ASR</TableCell>
                            <TableCell>ACD</TableCell>
                            <TableCell>SDP</TableCell>
                            <TableCell>NPF %</TableCell>
                            <TableCell>GAX %</TableCell>
                            <TableCell>INTL</TableCell>
                            <TableCell>IN MEDIA BL</TableCell>
                            <TableCell>GROUP</TableCell>
                            <TableCell>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.accountName}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.balance}</TableCell>
                                    <TableCell>{row.profit}</TableCell>
                                    <TableCell>{row.spentToday}</TableCell>
                                    <TableCell>{row.spentYest}</TableCell>
                                    <TableCell>{row.gain}</TableCell>
                                    <TableCell>{row.cps}</TableCell>
                                    <TableCell>{row.cpsPercent}</TableCell>
                                    <TableCell>{row.sess}</TableCell>
                                    <TableCell>{row.ratio}</TableCell>
                                    <TableCell>{row.asr}</TableCell>
                                    <TableCell>{row.acd}</TableCell>
                                    <TableCell>{row.sdp}</TableCell>
                                    <TableCell>{row.npf}</TableCell>
                                    <TableCell>{row.gax}</TableCell>
                                    <TableCell>{row.intl}</TableCell>
                                    <TableCell>{row.inMediaBl}</TableCell>
                                    <TableCell>{row.group}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                Settings
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Status
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Graphs
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Pricing
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                STIR/SHAKEN
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Post Note
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Set Reminder
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default SettingOverview
