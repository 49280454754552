import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

const AccountTransactions = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    const createData = (
        templateName,
        invoiceDescription,
        interval,
        startDate,
        endDate,
        method,
        quantity,
        rate,
        amount
    ) => ({
        templateName,
        invoiceDescription,
        interval,
        startDate,
        endDate,
        method,
        quantity,
        rate,
        amount,
    })

    const rows = Array.from({ length: 200 }, (_, index) =>
        createData(
            'Taxes and Fees',
            'Taxes and Fees',
            'Monthly',
            '10/10/2019',
            '12/31/2025',
            'Billed Usage',
            '-',
            '0.025',
            '-'
        )
    )

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>TEMPLATE NAME</TableCell>
                            <TableCell>INVOICE DESCRIPTION</TableCell>
                            <TableCell>INTERVAL</TableCell>
                            <TableCell>START DATE</TableCell>
                            <TableCell>END DATE</TableCell>
                            <TableCell>METHOD</TableCell>
                            <TableCell>QUANTITY</TableCell>
                            <TableCell>RATE</TableCell>
                            <TableCell>AMOUNT</TableCell>
                            <TableCell>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.templateName}</TableCell>
                                    <TableCell>
                                        {row.invoiceDescription}
                                    </TableCell>
                                    <TableCell>{row.interval}</TableCell>
                                    <TableCell>{row.startDate}</TableCell>
                                    <TableCell>{row.endDate}</TableCell>
                                    <TableCell>{row.method}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                    <TableCell>{row.rate}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={handleClick}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                Delete
                                            </MenuItem>
                                            {/* ... other actions */}
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default AccountTransactions
