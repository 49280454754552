import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import TextField from '@mui/material/TextField'

export default function CompanySettings() {
    const navigate = useNavigate()

    const goBackToAdminSettings = () => {
        navigate('/admin/settings')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box sx={{ padding: '20px 0 0 20px', display: 'flex' }}>
                <Button
                    sx={{
                        borderColor: '#393a3d',
                        minWidth: 0,
                        width: '38px',
                        height: '38px',
                        padding: '5px',
                    }}
                    variant={'outlined'}
                    onClick={goBackToAdminSettings}
                >
                    <ArrowBack sx={{ color: '#393a3d' }} />
                </Button>
                <Typography
                    sx={{
                        padding: '5px 0 0 10px',
                        color: '#393a3d',
                        fontWeight: 'bold',
                        paddingBottom: '15px',
                    }}
                >
                    Your Company
                </Typography>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Company details
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            name="legal_company_name"
                            label="Legal company Name"
                            size={'small'}
                            defaultValue={'SipNav Demo'}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={'SipNav Demo'}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={'300 200 100'}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={'test@email.com'}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="street_address"
                            label="Street Address"
                            size={'small'}
                            defaultValue={'Test street 74'}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="address_line_2"
                            label="Address Line 2"
                            size={'small'}
                            defaultValue={'Suite 800'}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                            <TextField
                                margin="normal"
                                required
                                name="zip_code"
                                label="Zip Code"
                                size={'small'}
                                defaultValue={'693-41'}
                            />
                            <TextField
                                margin="normal"
                                requiredgtz
                                name="state"
                                label="State"
                                size={'small'}
                                defaultValue={'MT'}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="country"
                                label="Country"
                                size={'small'}
                                defaultValue={'US'}
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            required
                            name="499_filer_id"
                            label="499 Filer ID"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Billing Department
                        </Typography>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">
                                Same as
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Same as"
                                defaultValue={'Company Details'}
                            >
                                <MenuItem selected value={'Company Details'}>
                                    Company Details
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="street_address"
                            label="Street Address"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="address_line_2"
                            label="Address Line 2"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                            <TextField
                                margin="normal"
                                required
                                name="zip_code"
                                label="Zip Code"
                                size={'small'}
                                defaultValue={''}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="state"
                                label="State"
                                size={'small'}
                                defaultValue={''}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="country"
                                label="Country"
                                size={'small'}
                                defaultValue={''}
                            />
                        </Box>
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Tech Support
                        </Typography>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">
                                Same as
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Same as"
                                defaultValue={'Company Details'}
                            >
                                <MenuItem selected value={'Company Details'}>
                                    Company Details
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="street_address"
                            label="Street Address"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="address_line_2"
                            label="Address Line 2"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                            <TextField
                                margin="normal"
                                required
                                name="zip_code"
                                label="Zip Code"
                                size={'small'}
                                defaultValue={''}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="state"
                                label="State"
                                size={'small'}
                                defaultValue={''}
                            />
                            <TextField
                                margin="normal"
                                required
                                name="country"
                                label="Country"
                                size={'small'}
                                defaultValue={''}
                            />
                        </Box>
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Customer Contact
                        </Typography>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">
                                Same as
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Same as"
                                defaultValue={'Company Details'}
                            >
                                <MenuItem selected value={'Company Details'}>
                                    Company Details
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Vendor Contact
                        </Typography>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">
                                Same as
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Same as"
                                defaultValue={'Company Details'}
                            >
                                <MenuItem selected value={'Company Details'}>
                                    Company Details
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
                <Card sx={{ width: 320, margin: '5px' }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Agent Contact
                        </Typography>
                        <FormControl sx={{ width: '200px' }}>
                            <InputLabel id="demo-simple-select-label">
                                Same as
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Same as"
                                defaultValue={'Company Details'}
                            >
                                <MenuItem selected value={'Company Details'}>
                                    Company Details
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            required
                            name="contact_name"
                            label="Contact Name"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="phone"
                            label="Phone"
                            size={'small'}
                            defaultValue={''}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="email"
                            label="Email"
                            size={'small'}
                            defaultValue={''}
                        />
                        <Button
                            size={'small'}
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, float: 'right', mr: 1 }}
                        >
                            Submit
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}
