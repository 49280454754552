import React, { useState } from 'react'
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Toolbar,
    Button,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    MenuItem,
    Card,
    CardContent,
    FormGroup,
    FormLabel,
    RadioGroup,
    Radio,
    InputAdornment,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import LoadingSpinner from '../../atoms/LoadingSpinner'

const NewAccount = () => {
    const navigate = useNavigate()

    const getTopUrlFromTab = (tab) => {
        switch (tab) {
            case 0: {
                return 'accounts'
            }
            case 1: {
                return 'carriers'
            }
            default:
                return 'accounts'
        }
    }

    const [selectedTopTab] = useState(0)

    const handleTopTabChange = (event, newValue) => {
        navigate(`/${getTopUrlFromTab(newValue)}`, { replace: true })
    }

    const goBackToAccounts = () => {
        navigate('/accounts')
    }

    const [formData, setFormData] = useState({
        account_name: 'abc',
        customer_name: '333333',
        account_id: 12345,
        account_number: 'ACC123456',
        status: 'Pending',
        account_group: 'Unassigned',
        cps_limit: 8,
        session_limit: 23,
        no_call_progress_limit: 46,
        ring_timer: 61,
        disable_call_restrictions: true,
        enforce_cid_block: 'Yes',
        cid_block_min_asr: 7,
        cid_block_min_acd: 7,
        cid_block_max_spd: 95,
        international: false,
        disable_rate_deck_validation: false,
        capacity_group: 'Group 1',
        block_wireless: true,
        block_non_wireless: false,
        ani_blacklist: 'Global',
        dnis_blacklist: false,
        block_matching_src_dst: 'NPANXX',
        remove_cid_name: true,
        dnc_block: 'Disabled',
        attestation_blocking: 'Type A',
        billinginfo_address: '123 Example St.',
        billinginfo_city: 'Exampleville',
        billinginfo_state: 'ExState',
        billinginfo_zip: '12345',
        billinginfo_country: 'Exampleland',
        billinginfo_billing_contact_name: 'John Billing',
        billinginfo_billing_phone_number: '+1234567890',
        billinginfo_billing_contact_mobile: '+0987654321',
        billinginfo_email_for_invoices: 'billing@example.com',
        billinginfo_account_taxation: 'Wholesale',
        billinginfo_im: 'john.im',
        billinginfo_terms: 'Net30',
        billinginfo_low_limit: 0,
        billinginfo_suspend_limit: -5,
        billinginfo_temp_suspend_limit: false,
        billinginfo_enable_cc_payment: false,
        billinginfo_minimal_cc_amount: 0,
        billinginfo_cc_fee: 0,
        billinginfo_allow_paypal: false,
        billinginfo_credit_net_amount: 0,
        billinginfo_charge_fixed_fee: 0,
        billinginfo_paypal_from_email: 'paypal@example.com',
        billinginfo_adding_the_fee: false,
        billinginfo_subtract_fee: true,
        billinginfo_do_not_send_confirmation_email: false,
        billinginfo_daily_balance_email: false,
        billinginfo_do_not_send_so_invoice: false,
        billinginfo_charge_fusf: false,
        billinginfo_did_prorate: false,
    })

    const [isLoading, setIsLoading] = React.useState(false)

    const handleChange = (event) => {
        console.log(event)
        const { name, value } = event.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }
    function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay))
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        await timeout(3000)
        navigate('/accounts')
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Tabs
                        value={selectedTopTab}
                        onChange={handleTopTabChange}
                        textColor="inherit"
                    >
                        <Tab label="Accounts" />
                        <Tab label="Carriers" />
                        <Tab label="Platform" />
                    </Tabs>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', p: 2 }}>
                <Button
                    sx={{
                        borderColor: '#393a3d',
                        minWidth: 0,
                        width: '38px',
                        height: '38px',
                        padding: '5px',
                    }}
                    variant={'outlined'}
                    onClick={goBackToAccounts}
                >
                    <ArrowBack sx={{ color: '#393a3d' }} />
                </Button>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: 'black',
                        padding: '8px 0 0 10px',
                    }}
                >
                    New Account
                </Typography>
            </Box>
            <Box role="tabpanel" sx={{ padding: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            p: 2,
                        }}
                    >
                        <Card sx={{ width: 700, margin: '5px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'black',
                                    padding: '15px 0 0 15px',
                                }}
                            >
                                Account Information
                            </Typography>
                            <CardContent>
                                <TextField
                                    label="Account Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Customer Name</InputLabel>
                                    <Select
                                        label="Customer Name"
                                        name="customer_name"
                                        value={formData.customer_name}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="333333">
                                            333333
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Account Id"
                                    name="account_id"
                                    value={formData.account_id}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    label="Account Number"
                                    name="account_number"
                                    value={formData.account_number}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        label="Status"
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="pending">
                                            Pending
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Account group</InputLabel>
                                    <Select
                                        label="Account group"
                                        name="account_group"
                                        value={formData.account_group}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="unassigned">
                                            Unassigned
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="CPS Limit"
                                    name="cps_limit"
                                    value={formData.cps_limit}
                                    onChange={handleChange}
                                    type="number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    label="Session Limit"
                                    name="session_limit"
                                    value={formData.session_limit}
                                    onChange={handleChange}
                                    type="number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    label="No Call Progress Limit"
                                    type="number"
                                    name="no_call_progress_limit"
                                    value={formData.no_call_progress_limit}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />

                                <TextField
                                    label="Ring Timer"
                                    type="number"
                                    name="ring_timer"
                                    value={formData.ring_timer}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Disable call restrictions
                                    </FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="disable_call_restrictions"
                                                    value={
                                                        formData.disable_call_restrictions
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="CID"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="disable_call_restrictions"
                                                    value={
                                                        formData.disable_call_restrictions
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Destination"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="enforce-cid-block-label">
                                        Enforce CID Block
                                    </InputLabel>
                                    <Select
                                        labelId="enforce-cid-block-label"
                                        label="Enforce CID Block"
                                        name="enforce_cid_block"
                                        value={formData.enforce_cid_block}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    label="CID Block Min ASR"
                                    type="number"
                                    name="cid_block_min_asr"
                                    value={formData.cid_block_min_asr}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />

                                <TextField
                                    label="CID Block Min ACD"
                                    type="number"
                                    name="cid_block_min_acd"
                                    value={formData.cid_block_min_acd}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />

                                <TextField
                                    label="CID Block Max SDP"
                                    type="number"
                                    name="cid_block_max_spd"
                                    value={formData.cid_block_max_spd}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="international"
                                            value={formData.international}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="International"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="disable_rate_deck_validation"
                                            value={
                                                formData.disable_rate_deck_validation
                                            }
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Disable Rate Deck Validation"
                                />

                                <FormGroup row>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Capacity Group
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            name="capacity_group"
                                            value={formData.capacity_group}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel
                                                value="group1"
                                                control={<Radio />}
                                                label="Group 1"
                                            />
                                            <FormControlLabel
                                                value="group2"
                                                control={<Radio />}
                                                label="Group 2"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup row>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Block Wireless
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="block_wireless"
                                                        value={
                                                            formData.block_wireless
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label="ANI"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="block_wireless"
                                                        value={
                                                            formData.block_wireless
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label="DNIS"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </FormGroup>
                                <FormGroup row>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Block Non Wireless
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="block_non_wireless"
                                                        value={
                                                            formData.block_non_wireless
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label="ANI"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="block_non_wireless"
                                                        value={
                                                            formData.block_non_wireless
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label="DNIS"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </FormGroup>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        ANI Blacklist
                                    </FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="ani_blacklist"
                                                    value={
                                                        formData.ani_blacklist
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Global"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="ani_blacklist"
                                                    value={
                                                        formData.ani_blacklist
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Custom"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormGroup row>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            DNIS Blacklist
                                        </FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="dnis_blacklist"
                                                    value={
                                                        formData.dnis_blacklist
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Global"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="dnis_blacklist"
                                                    value={
                                                        formData.dnis_blacklist
                                                    }
                                                    onChange={handleChange}
                                                />
                                            }
                                            label="Custom"
                                        />
                                    </FormControl>
                                </FormGroup>

                                <FormGroup row>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="block-matching-src-dst-label">
                                            Block Matching SRC/DST
                                        </InputLabel>
                                        <Select
                                            labelId="block-matching-src-dst-label"
                                            label="Block Matching SRC/DST"
                                            name="block_matching_src_dst"
                                            value={
                                                formData.block_matching_src_dst
                                            }
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="NPANXX">
                                                NPANXX
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>

                                <FormGroup row>
                                    <FormControlLabel
                                        label={'Remove CID name'}
                                        control={
                                            <Checkbox name="removeCIDName" />
                                        }
                                        name="remove_cid_name"
                                        value={formData.remove_cid_name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="somos-label">
                                            Somos
                                        </InputLabel>
                                        <Select
                                            labelId="somos-label"
                                            label="Somos"
                                            defaultValue=""
                                        >
                                            <MenuItem value="carrierOverride">
                                                Carrier Override
                                            </MenuItem>
                                            <MenuItem value="Option2">
                                                Option 2
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>

                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="dnc-block-label">
                                        DNC Block
                                    </InputLabel>
                                    <Select
                                        labelId="dnc-block-label"
                                        label="DNC Block"
                                        name="dnc_block"
                                        value={formData.dnc_block}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Enabled">
                                            Enabled
                                        </MenuItem>
                                        <MenuItem value="Disabled">
                                            Disabled
                                        </MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="attestation-blocking-label">
                                        Attestation Blocking
                                    </InputLabel>
                                    <Select
                                        labelId="attestation-blocking-label"
                                        label="Attestation Blocking"
                                        name="attestation_blocking"
                                        value={formData.attestation_blocking}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Option1">
                                            Option1
                                        </MenuItem>
                                        <MenuItem value="Option2">
                                            Option2
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: 700, margin: '5px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'black',
                                    padding: '15px 0 0 15px',
                                }}
                            >
                                Billing Information
                            </Typography>
                            <CardContent>
                                <TextField
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_address"
                                    value={formData.billinginfo_address}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_city"
                                    value={formData.billinginfo_city}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_state"
                                    value={formData.billinginfo_state}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Zip"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_zip"
                                    value={formData.billinginfo_zip}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Country"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_country"
                                    value={formData.billinginfo_country}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Billing Contact Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_billing_contact_name"
                                    value={
                                        formData.billinginfo_billing_contact_name
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Billing Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_billing_phone_number"
                                    value={
                                        formData.billinginfo_billing_phone_number
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Billing Contact Mobile"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_billing_contact_mobile"
                                    value={
                                        formData.billinginfo_billing_contact_mobile
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Email for Invoices"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_email_for_invoices"
                                    value={
                                        formData.billinginfo_email_for_invoices
                                    }
                                    onChange={handleChange}
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="account-taxation-label">
                                        Account Taxation
                                    </InputLabel>
                                    <Select
                                        labelId="account-taxation-label"
                                        label="Account Taxation"
                                        name="billinginfo_account_taxation"
                                        value={
                                            formData.billinginfo_account_taxation
                                        }
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Wholesale">
                                            Wholesale
                                        </MenuItem>
                                        <MenuItem value="Retail">
                                            Retail
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="IM"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_im"
                                    value={formData.billinginfo_im}
                                    onChange={handleChange}
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="terms-label">
                                        Terms
                                    </InputLabel>
                                    <Select
                                        labelId="terms-label"
                                        label="Terms"
                                        name="billinginfo_terms"
                                        value={formData.billinginfo_terms}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            --Select Term--
                                        </MenuItem>
                                        <MenuItem value="Net30">Net30</MenuItem>
                                        <MenuItem value="Net45">Net45</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Low Limit"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    name="billinginfo_low_limit"
                                    value={formData.billinginfo_low_limit}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Suspend Limit"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    name="billinginfo_suspend_limit"
                                    value={formData.billinginfo_suspend_limit}
                                    onChange={handleChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="tempSuspendLimit" />
                                    }
                                    label="Temp suspend limit"
                                    name="billinginfo_temp_suspend_limit"
                                    value={
                                        formData.billinginfo_temp_suspend_limit
                                    }
                                    onChange={handleChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox name="enableCCPayment" />
                                    }
                                    label="Enable CC Payment"
                                    name="billinginfo_enable_cc_payment"
                                    value={
                                        formData.billinginfo_enable_cc_payment
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Minimal CC amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    name="billinginfo_minimal_cc_amount"
                                    value={
                                        formData.billinginfo_minimal_cc_amount
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="CC Fee"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="billinginfo_cc_fee"
                                    value={formData.billinginfo_cc_fee}
                                    onChange={handleChange}
                                />
                                <FormControlLabel
                                    control={<Checkbox name="allowPaypal" />}
                                    label="Allow Paypal"
                                    name="billinginfo_allow_paypal"
                                    value={formData.billinginfo_allow_paypal}
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Credit Net Amount"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    name="billinginfo_credit_net_amount"
                                    value={
                                        formData.billinginfo_credit_net_amount
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="Charge Fixed Fee"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="billinginfo_charge_fixed_fee"
                                    value={
                                        formData.billinginfo_charge_fixed_fee
                                    }
                                    onChange={handleChange}
                                />
                                <TextField
                                    label="PayPal FROM email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="billinginfo_paypal_from_email"
                                    value={
                                        formData.billinginfo_paypal_from_email
                                    }
                                    onChange={handleChange}
                                />
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_adding_the_fee" />
                                        }
                                        label="Adding The Fee"
                                        name="billinginfo_adding_the_fee"
                                        value={
                                            formData.billinginfo_adding_the_fee
                                        }
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_subtract_fee" />
                                        }
                                        label="Subtract Fee"
                                        name="billinginfo_subtract_fee"
                                        value={
                                            formData.billinginfo_subtract_fee
                                        }
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_do_not_send_confirmation_email" />
                                        }
                                        label="Do not send confirmation email"
                                        name="billinginfo_do_not_send_confirmation_email"
                                        value={
                                            formData.billinginfo_do_not_send_confirmation_email
                                        }
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_daily_balance_email" />
                                        }
                                        label="Daily Balance Email"
                                        name="billinginfo_daily_balance_email"
                                        value={
                                            formData.billinginfo_daily_balance_email
                                        }
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_do_not_send_so_invoice" />
                                        }
                                        label="Do Not Send $0 Invoice"
                                        name="billinginfo_do_not_send_so_invoice"
                                        value={
                                            formData.billinginfo_do_not_send_so_invoice
                                        }
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_charge_fusf" />
                                        }
                                        label="Charge FUSF"
                                        name="billinginfo_charge_fusf"
                                        value={formData.billinginfo_charge_fusf}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="billinginfo_did_prorate" />
                                        }
                                        label="DID Prorate"
                                        name="billinginfo_did_prorate"
                                        value={formData.billinginfo_did_prorate}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ margin: '0 0 0 20px' }}
                    >
                        Submit
                    </Button>
                </form>
            </Box>
        </Box>
    )
}

export default NewAccount
